const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const baseURL = `${REACT_APP_API_URL}/api/v1`;

function eventSourceInstance(authorization = true, url?: string) {
  const eventSource = new EventSource(baseURL + url, {
    withCredentials: authorization,
  });

  return eventSource;
}

export default eventSourceInstance;
