import React, { memo, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Toolbar, ToolbarProps } from '@app/common';
import { Loader, MobileMenu, StyledPaper } from '@app/components';

interface Props extends PropsWithChildren {
  toolbarProps: ToolbarProps | null;
  leftContent: ReactNode;
  rightContent: ReactNode;
  pending: boolean;
}

const StyledGrid = styled(StyledPaper)`
  display: grid;
  grid-gap: 48px;
  grid-template-columns: 380px 1fr;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledGridItem = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: min-content;
`;

function LayoutProfile(props: Props) {
  const { toolbarProps, leftContent, rightContent, pending, children } = props;

  return (
    <>
      {!!toolbarProps && <Toolbar {...toolbarProps} />}
      {pending && <Loader />}
      {!pending && (
        <>
          <StyledGrid>
            <StyledGridItem>{leftContent}</StyledGridItem>
            <StyledGridItem>{rightContent}</StyledGridItem>
          </StyledGrid>
          {children}
          <MobileMenu />
        </>
      )}
    </>
  );
}

export default memo(LayoutProfile);
