import CustomCheckbox from 'components/CastomCheckbox';
import React, { memo, useCallback } from 'react';
import styles from './NotificationItemContent.module.scss';
import { Notification, NotificationTypeText } from '@app/models';
import { dateFormat } from '@app/helpers';
import { readNotifications } from '@app/api';

interface NotificationItemContentProps {
  notification: Notification;
  getData?: () => void;
  onClick?: () => void;
}

const NotificationItemContent = memo((props: NotificationItemContentProps) => {
  const { notification, getData, onClick } = props;

  const onReadNotification = useCallback(async () => {
    await readNotifications(notification.id);
    if (!!getData) await getData();
  }, [getData, notification.id]);

  const onClickNotification = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!onClick) {
        return;
      }

      onClick();
    },
    [onClick]
  );

  return (
    <li className={styles.notification}>
      <div className={styles.content}>
        <span className={styles.title}>
          {NotificationTypeText[notification.type]}
        </span>
        <p className={styles.subTitle}>{notification.message}</p>
        <span
          className={`${styles.link} ${
            notification.data ? '' : styles.disabled
          }`}
          onClick={onClickNotification}
        >
          Перейти
        </span>
      </div>
      <div className={styles.date}>
        <div className={styles.dateContainer}>
          <p className={styles.day}>{dateFormat(notification.createdAt)}</p>
          <span className={styles.time}>
            {dateFormat(notification.createdAt, 'hh:mm')}
          </span>
        </div>
        <CustomCheckbox
          onClick={onReadNotification}
          toggleCheckbox={notification.isRead}
        />
      </div>
    </li>
  );
});

export default NotificationItemContent;
