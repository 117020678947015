import { Company } from './Company';
import { WithCreatedUpdated } from './Default';
import { Kato } from './Kato';
import { Oked } from './Oked';
import { Portfolio } from './Portfolio';

export interface UserPhone {
  phone: string;
  isPrefer: boolean;
  isWhatsApp: boolean;
}

export enum UserType {
  INDIVIDUAL = 10,
  ENTITY = 20,
}

export enum UserGender {
  MALE = 10,
  FAMALE = 20,
}

export const UserGenderName: Record<UserGender, string> = {
  [UserGender.MALE]: 'Мужской',
  [UserGender.FAMALE]: 'Женский',
};

export enum UserEmployment {
  FULL = 10,
  PART_TIME = 20,
  DISTANT = 30,
}

export const UserEmploymentNames: Record<UserEmployment, string> = {
  [UserEmployment.FULL]: 'Полная занятость',
  [UserEmployment.PART_TIME]: 'Частичная занятость',
  [UserEmployment.DISTANT]: 'Удаленная работа',
};

export enum UserSchedule {
  FLEXIBLE = 10,
  SHIFT = 20,
  REMOVABLE = 30,
}

export const UserScheduleNames: Record<UserSchedule, string> = {
  [UserSchedule.FLEXIBLE]: 'Гибкий графий',
  [UserSchedule.SHIFT]: 'Вахта',
  [UserSchedule.REMOVABLE]: 'Сменный',
};

export enum UserStatus {
  FREE = 10,
  BUSY = 20,
}

export const UserStatusNames: Record<UserStatus, string> = {
  [UserStatus.FREE]: 'Свободен',
  [UserStatus.BUSY]: 'Занят',
};

export enum UserReadyForBusinessTrips {
  YES = 10,
  NO = 20,
}

export const UserReadyForBusinessTripsNames: Record<
  UserReadyForBusinessTrips,
  string
> = {
  [UserReadyForBusinessTrips.YES]: 'Да',
  [UserReadyForBusinessTrips.NO]: 'Нет',
};

export enum UserFamilyStatus {
  NOT_MARRIED = 0,
  MARRIED = 1,
}

export const UserFamilyStatusNames: Record<UserFamilyStatus, string> = {
  [UserFamilyStatus.NOT_MARRIED]: 'Не женат (не замужем)',
  [UserFamilyStatus.MARRIED]: 'Женат (замужем)',
};

export interface User extends WithCreatedUpdated {
  id: string;
  phone: string;
  firstName: string;
  lastName: string;
  middleName: string;
  katoCode: string;
  email: string;
  type: UserType;
  companies: Company[];
  kato: Kato;
  password: string;
  gender: UserGender | null;
  address: string;
  instagram: string | null;
  telegram: string | null;
  employment: UserEmployment | null;
  schedule: UserSchedule | null;
  readyForBusinessTrips: boolean | null;
  additionalInformation: string;
  okeds: Oked[];
  phones: string | null;
  webSite: string | null;
  specialization: string | null;
  iin: string | null;
  whatsAppNumber: string | null;
  familyStatus: UserFamilyStatus;
  status: UserStatus;
  imageIds: string | null;
  filesIds: string | null;
  avatarFilesIds: string | null;
  rating: number;
  portfolios: Portfolio[];
  active: boolean;
  blocked: boolean;
}

export interface ChangeUserPassword {
  code?: string | number;
  oldPassword?: string;
  user: {
    password: string;
    phone: string;
  };
}
