import { FC, memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './NotificationItem.module.scss';
import { Notification, NotificationTypeText } from '@app/models';
import { dateFormat } from '@app/helpers';

interface INotificationItem {
  notification: Notification;
}

const NotificationItem: FC<INotificationItem> = (props) => {
  const { notification } = props;
  const location = useLocation();

  const path = useMemo(() => '/profile/notification', [location]);

  return (
    <li className={styles.notificationItem}>
      <div className={styles.notificationContent}>
        <h2 className={styles.title}>
          {NotificationTypeText[notification.type]}
        </h2>
        <span className={styles.text}>{notification.message}</span>
        <Link to={path} className={styles.link}>
          Перейти
        </Link>
      </div>
      <div className={styles.notificationContext}>
        <p className={styles.day}>{dateFormat(notification.createdAt)}</p>
        <span className={styles.time}>
          {dateFormat(notification.createdAt, 'hh:mm')}
        </span>
        <span className={styles.count}>1</span>
      </div>
    </li>
  );
};

export default memo(NotificationItem);
