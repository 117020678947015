import { useCallback, useEffect, useRef, useState } from 'react';
import { isAfter } from 'date-fns';
import { capitalize, getCompanyTypeByName } from '@app/helpers';
import { CompanyTypeNames, CompanyTypes } from '@app/models';

const storage = 'PKCS12';
const module = 'kz.gov.pki.knca.commonUtils';

const getKeyInfo = {
  module,
  method: 'getKeyInfo',
  args: [storage],
};

const getSignBase64Method = (data: string) => ({
  module,
  method: 'createCAdESFromBase64',
  args: [storage, 'SIGNATURE', data, false],
});

interface ResponseObject {
  certNotAfter: string;
  certNotBefore: string;
  subjectDn: string;
}

interface Message {
  result: {
    version: string;
  };
  code: string;
  responseObject: ResponseObject;
}

export interface DN {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  iin: string;
  company?: {
    bin: string;
    name: string;
    type: CompanyTypes;
  };
}

//CN=ШАРИПКАНОВ ЕРТАЙ,SURNAME=ШАРИПКАНОВ,SERIALNUMBER=IIN930606350520,C=KZ,G=СЕРИККАНОВИЧ,E=sharipkanov@gmail.com
//SURNAME=ДАУТ,CN=ДАУТ ДАУРЕН,SERIALNUMBER=IIN900604350206,C=KZ,OU=BIN150140003512,G=ҚАНАТҰЛЫ,O=ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ \"BI INNOVATIONS\",E=DAUT_D@BI.GROUP

function useNcaLayer() {
  const socket = useRef<WebSocket | null>(null);
  const [connected, setConnected] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [dn, setDn] = useState<DN>({} as DN);

  const getDnValue = useCallback((subjectDn: string[], key: string) => {
    const data = subjectDn.find((data) => data.split('=')[0] === key);

    if (!data) {
      return null;
    }

    return data.split('=')[1];
  }, []);

  const parseSubjectDn = useCallback(
    (subjectDn: string[]) => {
      const dnData = {} as DN;

      const cn = getDnValue(subjectDn, 'CN')!.split(' ');

      dnData.firstName = capitalize(cn[1]).trim();
      dnData.lastName = capitalize(cn[0]).trim();
      dnData.middleName = capitalize(getDnValue(subjectDn, 'G')!).trim();
      dnData.email = !!getDnValue(subjectDn, 'E')
        ? getDnValue(subjectDn, 'E')!.toLocaleLowerCase().trim()
        : '';
      dnData.iin = getDnValue(subjectDn, 'SERIALNUMBER')!
        .replace('IIN', '')
        .trim();

      const bin = getDnValue(subjectDn, 'OU');

      if (!!bin) {
        const nameWithType = getDnValue(subjectDn, 'O')!;
        let name = '';
        let companyType: CompanyTypes = '' as unknown as CompanyTypes;

        Object.values(CompanyTypeNames).forEach((typeName) => {
          if (nameWithType.toUpperCase().indexOf(typeName) > -1) {
            name = nameWithType.substring(typeName.length, nameWithType.length);
            companyType = getCompanyTypeByName(typeName);
          }
        });

        dnData.company = {
          bin: bin.replace('BIN', '').trim(),
          name: name.replace(/\\/g, '').replace(/["]+/g, '').trim(),
          type: companyType,
        };
      }

      return dnData;
    },
    [getDnValue]
  );

  const parseCompanyData = useCallback(
    (responseObject: ResponseObject) => {
      const { subjectDn } = responseObject;

      if (!subjectDn) {
        return;
      }

      const dnData = parseSubjectDn(subjectDn.split(','));

      setDn(dnData);
    },
    [parseSubjectDn]
  );

  const onMessage = useCallback(
    (e: MessageEvent) => {
      const { code, responseObject }: Message = JSON.parse(e.data);

      if (!code || code !== '200') {
        return;
      }

      if (responseObject) {
        const { certNotAfter } = responseObject;

        if (certNotAfter) {
          if (isAfter(new Date(), new Date(certNotAfter))) {
            setIsExpired(true);
          } else {
            parseCompanyData(responseObject);
          }
        }
      }
    },
    [parseCompanyData]
  );

  const onError = useCallback((e: any) => {
    setConnected(false);
  }, []);

  const onOpen = useCallback((e: Event) => {
    if (e.type === 'open') {
      setConnected(true);
    }
  }, []);

  const connectToSocket = useCallback(() => {
    socket.current = new WebSocket('wss://127.0.0.1:13579');

    socket.current.onopen = onOpen;

    socket.current.onmessage = onMessage;

    socket.current.onerror = onError;
  }, [onError, onMessage, onOpen]);

  const chooseCertificate = useCallback(() => {
    if (!connected) {
      connectToSocket();
    }

    if (socket.current?.readyState === 1) {
      socket.current?.send(JSON.stringify(getKeyInfo));
    }
  }, [connectToSocket, connected]);

  const signBase64 = useCallback(
    (file: string) => {
      if (!connected) {
        connectToSocket();
      }

      if (socket.current?.readyState === 1) {
        socket.current?.send(JSON.stringify(getSignBase64Method(file)));
      }
    },
    [connectToSocket, connected]
  );

  useEffect(() => {
    connectToSocket();
  }, [connectToSocket]);

  return {
    connected,
    isExpired,
    chooseCertificate,
    dn,
    signBase64,
  };
}

export default useNcaLayer;
