import React, {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  FilePicker,
  Paper,
  PortfolioList,
  Tabs,
} from '@app/components';
import {
  IconEdit16,
  IconEmail16,
  IconPhone16,
  IconPlusCircle24,
  IconWhatsApp16,
} from '@app/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ModalAddEmployee, ModalReport } from '@app/modals';
import { LayoutProfile } from '@app/layouts';
import { ProfileCompanyAdditionalInfo, ProfileCompanyInfo } from './components';
import { deleteCompanyFile, uploadCompanyFiles } from '@app/api';
import { Company, TenderTypes } from '@app/models';
import { ProfileAvatar } from '../../pages/Profile/components';
import AboutUser from '../../pages/Profile/components/AboutUser';
import { useNotification, useUserContext } from '@app/providers';
import Reviews from '../Reviews';
import { UsersSearch } from '@app/common';

interface Props {
  company: Company;
  updateCompany?: (company: Partial<Company>) => void;
  editable?: boolean;
  showHeader?: boolean;
}

const NOT_SPECIFIED = 'Не указан';

const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledEmployeesWrapper = styled.div``;

const StyledEmployeesButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledBlockForButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 15px;

  > button + button,
  button + a,
  a + button {
    margin-left: 8px;
  }
`;

const StyledStatus = styled.p<{ color?: 'red' | 'green' }>`
  background: ${(props) => (props.color ? '#BA1B1B' : '#25aa51')};
  border-radius: 4px;
  padding: 6px 8px;
  color: #fff;
  margin: 0 0 0 16px;
`;

function ProfileCompany(props: Props) {
  const { company, updateCompany, editable = false, showHeader = true } = props;
  const { showNotification } = useNotification();
  const {
    user: authUser,
    company: authCompany,
    currentProfile,
    showSignInModal,
  } = useUserContext();
  const navigate = useNavigate();
  const [addEmployeeModalOpen, setAddEmployeeModalOpen] =
    useState<boolean>(false);
  const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);

  const onClickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onClickEdit = useCallback(() => {
    navigate(`/profile/company/${company!.bin}/edit`);
  }, [company, navigate]);

  const onShowAddEmployeeModal = useCallback(() => {
    setAddEmployeeModalOpen(true);
  }, []);

  const onCloseAddEmployeeModal = useCallback(() => {
    setAddEmployeeModalOpen(false);
  }, []);

  const onChangeFiles = useCallback(
    async (files: File[]) => {
      try {
        const response = await uploadCompanyFiles(company!.id, files);

        if (updateCompany) {
          updateCompany({
            ...company,
            filesIds: response.filesIds,
          });
        }
      } catch (e) {}
    },
    [updateCompany, company]
  );

  const onDeleteFile = useCallback(
    async (fileId: string) => {
      try {
        if (updateCompany) {
          updateCompany({
            filesIds: company
              .filesIds!.split(',')
              .filter((item) => item !== fileId)
              .join(','),
          });
        }

        await deleteCompanyFile(company.id, fileId);

        return true;
      } catch (e) {
        return false;
      }
    },
    [updateCompany, company]
  );

  const onClickShare = useCallback(async () => {
    try {
      if (!authUser.authenticated) {
        showSignInModal();

        return;
      }
      const url = `${window.location.hostname}/company/${company?.bin}`;
      await navigator.clipboard.writeText(url);

      showNotification({
        variant: 'success',
        message: 'Ссылка скопирована в буфер обмена',
      });
    } catch (e) {
      showNotification({
        variant: 'error',
        message: 'Ссылка не была скопирована',
      });
    }
  }, [authUser.authenticated, company?.bin, showNotification, showSignInModal]);

  const onClickReport = useCallback(() => {
    setReportModalVisible(true);
  }, []);

  const onCloseReportModal = useCallback(() => {
    setReportModalVisible(false);
  }, []);

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!company) {
      return [];
    }

    const returnActionButtons = [
      <Button
        text="Поделиться"
        disabled={currentProfile.blocked}
        onClick={onClickShare}
      />,
    ];

    if (currentProfile.id !== company.id) {
      returnActionButtons.push(
        <Button
          text="Пожаловаться"
          color="danger"
          onClick={onClickReport}
          disabled={currentProfile.blocked}
        />
      );
    }

    returnActionButtons.push(
      <a
        className="section-author__footer-whatsapp"
        href={`mailto:+${company.email}`}
      >
        <IconEmail16 />
      </a>
    );

    if (!!company.whatsAppNumber) {
      returnActionButtons.push(
        <a
          className="section-author__footer-whatsapp"
          href={`https://api.whatsapp.com/send?phone=${company.whatsAppNumber}`}
        >
          <IconWhatsApp16 />
        </a>
      );
    }
    if (company.phones) {
      returnActionButtons.push(
        <a
          className="section-author__footer-whatsapp"
          href={`tel:+${company?.phones[0]}`}
        >
          <IconPhone16 />
        </a>
      );
    }

    return [...returnActionButtons];
  }, [
    company,
    currentProfile.blocked,
    currentProfile.id,
    onClickShare,
    onClickReport,
  ]);

  const leftContent = useMemo(() => {
    if (!company) {
      return null;
    }

    return (
      <>
        <ProfileAvatar
          avatarFiles={
            !!company.avatarFilesIds ? company.avatarFilesIds.split(',')[0] : ''
          }
          editable={false}
        />
        <AboutUser
          ratingUser={company.rating}
          views={14}
          lastDateUpdate={9}
          email={company.email}
          instagram={company.instagram || NOT_SPECIFIED}
          telegram={company.telegram || NOT_SPECIFIED}
          phones={company.phones}
          whatAppNumber={company.whatsAppNumber}
        />
      </>
    );
  }, [company]);

  const rightContent = useMemo(() => {
    if (!company) {
      return null;
    }

    return <ProfileCompanyInfo company={company} />;
  }, [company]);

  const tabLabels = useMemo<string[]>(() => {
    const labels = ['Профессиональные данные'];

    if (editable) {
      labels.push('Сотрудники');
    }

    if (editable) {
      labels.push('Документы и Файлы');
    }

    return [...labels, 'Опыт работы', 'Отзывы'];
  }, [editable]);

  const tabContent = useMemo<ReactNode[]>(() => {
    //Профессиональные данные
    //Документы и Файлы
    const content = [
      <StyledGrid>
        <ProfileCompanyAdditionalInfo company={company} />
      </StyledGrid>,
    ];
    //Профессиональные данные
    //Документы и Файлы

    if (editable) {
      /*Сотрудники*/
      content.push(
        <StyledEmployeesWrapper>
          <StyledEmployeesButtonContainer>
            <Button
              text="Добавить сотрудника"
              icon={IconPlusCircle24}
              onClick={onShowAddEmployeeModal}
              disabled={company.blocked}
            />
          </StyledEmployeesButtonContainer>
          <UsersSearch
            defaultParams={{ companyId: company.id, type: undefined }}
            initialFilter={{ tenderType: TenderTypes.OFFER }}
            isRenderFilter={false}
            invertBgColorCard
          />
          <ModalAddEmployee
            visible={addEmployeeModalOpen}
            onClose={onCloseAddEmployeeModal}
          />
        </StyledEmployeesWrapper>
      );
    }

    if (editable) {
      content.push(
        <FilePicker
          loadableFiles={!!company.filesIds ? company.filesIds.split(',') : []}
          onChange={onChangeFiles}
          editable={editable && !company.blocked}
          onDelete={onDeleteFile}
        />
      );
    }

    /*Опыт работы*/
    /*Отзывы*/
    return [
      ...content,
      <PortfolioList
        companyId={company.id}
        editable={editable && !company.blocked}
      />,
      <Reviews
        companyId={company.id}
        showButton={
          (!authCompany || company.id !== authCompany.id) && !company.blocked
        }
      />,
    ];
  }, [
    addEmployeeModalOpen,
    authCompany,
    company,
    editable,
    onChangeFiles,
    onCloseAddEmployeeModal,
    onDeleteFile,
    onShowAddEmployeeModal,
  ]);

  const rightControls = useMemo<ReactNode[]>(() => {
    if (authUser.id === company?.owner.id)
      return [
        <Button
          text="Редактировать"
          icon={IconEdit16}
          onClick={onClickEdit}
          disabled={company.blocked}
        />,
      ];
    return [];
  }, [authUser.id, company.blocked, company?.owner.id, onClickEdit]);

  const leftInfo = useMemo<ReactNode[]>(() => {
    if (company.blocked)
      return [
        <StyledStatus color={'red'}>Компания заблокирована</StyledStatus>,
      ];
    return [];
  }, [company.blocked]);

  return (
    <StyledWrapper>
      <LayoutProfile
        pending={false}
        toolbarProps={
          showHeader
            ? {
                onClickBack,
                title: 'Данные организации',
                rightControls: editable ? rightControls : [],
                leftInfo,
              }
            : null
        }
        leftContent={leftContent}
        rightContent={rightContent}
      >
        <Paper>
          <Tabs labels={tabLabels}>
            {tabContent.map((elem, index) => {
              return <Fragment key={index}>{elem}</Fragment>;
            })}
          </Tabs>
          {!editable && authUser.authenticated && (
            <div className="section-author__footer">
              <StyledBlockForButton>
                {actionButtons.map((action, actionIndex) => (
                  <Fragment key={actionIndex}>{action}</Fragment>
                ))}
              </StyledBlockForButton>
            </div>
          )}
        </Paper>
      </LayoutProfile>
      {company && (
        <ModalReport
          company={company}
          visible={reportModalVisible}
          onClose={onCloseReportModal}
        />
      )}
    </StyledWrapper>
  );
}

export default memo(ProfileCompany);
