import {
  InviteSignUpPage,
  NotFoundPage,
  PageAboutUs,
  PageCompany,
  PageHome,
  PageNews,
  PagePrivacy,
  PageUser,
  ProfilePage,
  SignUpPage,
  TenderPage,
  VehiclePage,
} from '@app/pages';
import NewsCardPage from 'pages/News/components/NewsCardPage';
import React from 'react';

export enum AppRoutes {
  PRIVACY = 'privacy',
  ABOUT_US = 'about-us',
  NEWS = 'news',
  NEWS_ID = 'news-id',
  TENDER_ID = 'tender-id',
  VEHICLES_ID = 'vehicles-id',
  PROFILE = 'profile',
  SIGN_UP = 'sign-up',
  INVITE_SIGN_UP = 'invite-sign-up',
  NOT_FOUND = '404',
  HOME = 'home',
  COMPANY_ID = 'company-id',
  USER_ID = 'user-id',
}

export enum AppRoutesUserAuth {
  AUTH = 'authenticated',
  NOT_AUTH = 'not-authenticated',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.PRIVACY]: '/privacy',
  [AppRoutes.ABOUT_US]: '/about-us',
  [AppRoutes.NEWS]: '/news',
  [AppRoutes.NEWS_ID]: '/news/:id',
  [AppRoutes.TENDER_ID]: '/tender/:id',
  [AppRoutes.VEHICLES_ID]: '/vehicles/:id',
  [AppRoutes.NOT_FOUND]: '/404',
  [AppRoutes.HOME]: '*',
  //Только для авторизованного
  [AppRoutes.PROFILE]: '/profile/*',
  //Только для не авторизованного
  [AppRoutes.SIGN_UP]: '/sign-up',
  [AppRoutes.INVITE_SIGN_UP]: '/invite/sign-up',
  [AppRoutes.COMPANY_ID]: '/company/:bin',
  [AppRoutes.USER_ID]: '/user/:id',
};

export const routeConfigForAll = {
  [AppRoutes.PRIVACY]: {
    path: RoutePath[AppRoutes.PRIVACY],
    element: <PagePrivacy />,
  },
  [AppRoutes.ABOUT_US]: {
    path: RoutePath[AppRoutes.ABOUT_US],
    element: <PageAboutUs />,
  },
  [AppRoutes.NEWS]: {
    path: RoutePath[AppRoutes.NEWS],
    element: <PageNews />,
  },
  [AppRoutes.NEWS_ID]: {
    path: RoutePath[AppRoutes.NEWS_ID],
    element: <NewsCardPage />,
  },
  [AppRoutes.TENDER_ID]: {
    path: RoutePath[AppRoutes.TENDER_ID],
    element: <TenderPage />,
  },
  [AppRoutes.VEHICLES_ID]: {
    path: RoutePath[AppRoutes.VEHICLES_ID],
    element: <VehiclePage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath[AppRoutes.NOT_FOUND],
    element: <NotFoundPage />,
  },
  [AppRoutes.HOME]: {
    path: RoutePath[AppRoutes.HOME],
    element: <PageHome />,
  },
  [AppRoutes.COMPANY_ID]: {
    path: RoutePath[AppRoutes.COMPANY_ID],
    element: <PageCompany />,
  },
  [AppRoutes.USER_ID]: {
    path: RoutePath[AppRoutes.USER_ID],
    element: <PageUser />,
  },
};

export const routeConfigForAuthenticated = {
  [AppRoutes.PROFILE]: {
    path: RoutePath[AppRoutes.PROFILE],
    element: <ProfilePage />,
  },
};
export const routeConfigForNotAuthenticated = {
  [AppRoutes.SIGN_UP]: {
    path: RoutePath[AppRoutes.SIGN_UP],
    element: <SignUpPage />,
  },
  [AppRoutes.INVITE_SIGN_UP]: {
    path: RoutePath[AppRoutes.INVITE_SIGN_UP],
    element: <InviteSignUpPage />,
  },
};

export const routeConfigByType = {
  [AppRoutesUserAuth.AUTH]: {
    ...routeConfigForAuthenticated,
    ...routeConfigForAll,
  },
  [AppRoutesUserAuth.NOT_AUTH]: {
    ...routeConfigForNotAuthenticated,
    ...routeConfigForAll,
  },
};
