import { Checkbox } from '@app/components';
import React, { memo, useCallback, useState } from 'react';
import { DN } from '../../../../libs';
import { UserType } from '@app/models';
import { ExpandableForm, NCALayer } from '@app/common';
import { useNotification } from '@app/providers';
import { checkCompanyByBin } from '@app/api';

interface Props {
  defaultExpanded: boolean;
  onSubmit: (type: UserType, dnData: DN | null) => void;
}

function UserTypeForm(props: Props) {
  const { defaultExpanded, onSubmit } = props;
  const { showNotification } = useNotification();
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [userType, setUserType] = useState<UserType | null>(null);

  const onChangeEntity = useCallback(() => {
    if (userType === UserType.ENTITY) {
      return;
    }

    setUserType(UserType.ENTITY);
  }, [userType]);

  const onChangeIndividual = useCallback(() => {
    if (userType === UserType.INDIVIDUAL) {
      return;
    }

    setUserType(UserType.INDIVIDUAL);
  }, [userType]);

  const onChangeSwitch = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!userType || userType === UserType.ENTITY) {
        return;
      }

      setExpanded(false);
      onSubmit(userType, null);
    },
    [onSubmit, userType]
  );

  const onChangeDN = useCallback(
    async (dn: DN) => {
      if (!dn.company) {
        showNotification({
          variant: 'error',
          message: `Вы не являетесь юридическим лицом`,
        });

        return;
      }
      const hasCompany = await checkCompanyByBin(dn.company!.bin);

      if (hasCompany) {
        showNotification({
          variant: 'error',
          message: `Компания с БИН-ом: ${dn.company!.bin} уже зарегистрирова`,
        });

        return;
      }

      setExpanded(false);

      onSubmit(userType as UserType, dn);
    },
    [onSubmit, showNotification, userType]
  );

  return (
    <>
      <ExpandableForm
        title="Тип пользователя"
        description={
          userType === UserType.INDIVIDUAL
            ? 'Физическое лицо'
            : 'Юридическое лицо'
        }
        expanded={expanded}
        onChangeSwitch={onChangeSwitch}
        onSubmit={onSubmitForm}
        disabled={!userType || userType === UserType.ENTITY || !expanded}
        submitLabel="Далее"
        disableSwitcher
      >
        <Checkbox
          label="Физическое лицо"
          checked={userType === UserType.INDIVIDUAL}
          onChange={onChangeIndividual}
          variant="radio"
        />
        <Checkbox
          label="Юридическое лицо"
          checked={userType === UserType.ENTITY}
          onChange={onChangeEntity}
          variant="radio"
        />
      </ExpandableForm>
      {userType === UserType.ENTITY && expanded && (
        <NCALayer onChange={onChangeDN} />
      )}
    </>
  );
}

export default memo(UserTypeForm);
