export enum Measure {
  MILLI_MET = 10, // миллиметры
  CENTI_MET = 11, // сантиметры
  METRE = 12, // метры
  KV_MET = 13, // квадратные метры
  K_MET = 14, // километры
  KV_K_MET = 15, // квадратные километры

  SEC = 30, // секунды
  MIN = 31, // минуты
  HOUR = 32, // час
  DAY = 33, // день
  WEEK = 34, // неделя
  MONTH = 35, // месяц
  YEAR = 36, // год

  MILLI_GRAM = 50, // миллиграмм
  GRAM = 51, // грамм
  KILO = 52, // килограммы
  TON = 53, // тонны

  KZT = 70, // тенге
}

export const MeasureLabelsFull: Record<Measure, string> = {
  [Measure.MILLI_MET]: 'миллиметры',
  [Measure.CENTI_MET]: 'сантиметры',
  [Measure.METRE]: 'метры',
  [Measure.KV_MET]: 'квадратные метры',
  [Measure.K_MET]: 'километры',
  [Measure.KV_K_MET]: 'квадратные километры',

  [Measure.SEC]: 'секунды',
  [Measure.MIN]: 'минуты',
  [Measure.HOUR]: 'час',
  [Measure.DAY]: 'день',
  [Measure.WEEK]: 'неделя',
  [Measure.MONTH]: 'месяц',
  [Measure.YEAR]: 'год',

  [Measure.MILLI_GRAM]: 'миллиграмм',
  [Measure.GRAM]: 'грамм',
  [Measure.KILO]: 'килограммы',
  [Measure.TON]: 'тонны',

  [Measure.KZT]: 'тенге',
};

export const MeasureLabelsShort: Record<Measure, string> = {
  [Measure.MILLI_MET]: 'мм',
  [Measure.CENTI_MET]: 'см',
  [Measure.METRE]: 'м',
  [Measure.KV_MET]: 'кв.м',
  [Measure.K_MET]: 'км',
  [Measure.KV_K_MET]: 'кв.км',

  [Measure.SEC]: 'сек',
  [Measure.MIN]: 'мин',
  [Measure.HOUR]: 'ч',
  [Measure.DAY]: 'д',
  [Measure.WEEK]: 'н',
  [Measure.MONTH]: 'мес',
  [Measure.YEAR]: 'год',

  [Measure.MILLI_GRAM]: 'мг',
  [Measure.GRAM]: 'г',
  [Measure.KILO]: 'кг',
  [Measure.TON]: 'т',

  [Measure.KZT]: '₸',
};
