import { LayoutCabinet } from '@app/layouts';
import React, { memo, useMemo } from 'react';
import { Sidebar } from './components';
import { Navigation } from './components/Sidebar/Sidebar';
import {
  IconAccount,
  IconBookMark,
  IconBrickWall24,
  IconInfo24,
  IconMoneyBag24,
  IconNotificationSidebar24,
  IconScrewDriver24,
  IconSpecialist,
  IconTenderIcon,
  IconTruck24,
} from '@app/icons';
import { Navigate, Route, Routes } from 'react-router-dom';
import './styles.scss';
import { useUserContext } from 'providers/UserProvider';
import { UserType } from '@app/models';
import {
  AppRoutesUserType,
  routeConfigByTypeProfile,
} from '../../config/routeConfig/profile';

interface Props {}

function ProfilePage(props: Props) {
  const { user, currentProfile } = useUserContext();

  const navigationItems = useMemo<Navigation[]>(() => {
    const returnNavigationItems: Navigation[] = [
      {
        title: 'Главная',
        path: '/',
        icon: IconInfo24,
      },
      {
        title: 'Профиль',
        path:
          currentProfile.currentType === 'user'
            ? '/profile'
            : `/profile/company/${currentProfile.bin}`,
        icon: IconAccount,
      },
    ];

    if (
      currentProfile.currentType === 'user' &&
      currentProfile.type !== UserType.INDIVIDUAL
    ) {
      return [...returnNavigationItems];
    }

    if (currentProfile.blocked) {
      return [
        ...returnNavigationItems,
        {
          title: 'Уведомление ',
          path: '/profile/notification',
          icon: IconNotificationSidebar24,
        },
      ];
    }

    if (user.type === UserType.ENTITY) {
      returnNavigationItems.push({
        title: 'СМР',
        path: '/profile/tender/contractor',
        icon: IconScrewDriver24,
      });

      returnNavigationItems.push({
        title: 'Материалы',
        path: '/profile/tender/supplier',
        icon: IconBrickWall24,
      });
    }

    return [
      ...returnNavigationItems,
      {
        title: 'Спецтехника',
        path: '/profile/vehicles',
        icon: IconTruck24,
      },
      {
        title: 'Специалисты ',
        path: '/profile/specialists',
        icon: IconSpecialist,
      },
      {
        title: 'Партнеры',
        path: '/profile/partners',
        icon: IconMoneyBag24,
      },
      {
        title: 'Тендеры',
        path: '/profile/gov-tenders',
        icon: IconTenderIcon,
      },
      {
        title: 'Избранное',
        path: '/profile/favorites',
        icon: IconBookMark,
      },
      {
        title: 'Уведомление ',
        path: '/profile/notification',
        icon: IconNotificationSidebar24,
      },
      // {
      //   title: 'Дашборд ',
      //   path: '/profile/dashboard',
      //   icon: IconDashboard,
      // },
      // {
      //   title: 'Настройки',
      //   path: '/profile',
      //   icon: IconCog24,
      // },
    ];
  }, [currentProfile, user.type]);

  const userType = (): AppRoutesUserType => {
    if (user.type === UserType.INDIVIDUAL) {
      return AppRoutesUserType.INDIVIDUAL;
    }
    if (
      user.type === UserType.ENTITY &&
      currentProfile.currentType === 'company'
    ) {
      return AppRoutesUserType.ENTITY;
    }
    return AppRoutesUserType.ENTITY_INDIVIDUAL_USER;
  };

  return (
    <LayoutCabinet className="p-profile">
      <div className="p-profile__sidebar">
        <ul className="p-profile__nav">
          <Sidebar data={navigationItems} />
        </ul>
      </div>
      <div className="p-profile__content">
        <Routes>
          {Object.values(routeConfigByTypeProfile[userType()]).map(
            ({ element, path }) => (
              <Route key={path} path={path} element={element} />
            )
          )}
          {userType() === AppRoutesUserType.ENTITY ? (
            <Route
              path="*"
              element={<Navigate replace to="/profile/company" />}
            />
          ) : (
            <Route path="*" element={<Navigate replace to="/profile" />} />
          )}
        </Routes>
      </div>
    </LayoutCabinet>
  );
}

export default memo(ProfilePage);
