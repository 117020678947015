import React, { Fragment, memo, ReactNode, useCallback } from 'react';
import {
  CardAuthor,
  CardAuthorProps,
  CardDescription,
  CardInfo as CardInfoComponent,
  CardInfoProps,
  CardPrice,
  CardStatus,
  CardThumb,
  CardTitle,
} from './components';
import Button from '../Button';
import StarRating from 'components/StarRating';
import styled, { css } from 'styled-components';
import CardInlineLeft from './components/CardInlineLeft';
import { Measure, VehicleStatus } from '@app/models';

interface IHorizontal {
  horizontal: boolean;
  gridButtons?: number;
}

const StyledCardContainer = styled.div<IHorizontal>`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  box-shadow: 0 0 10px rgba(64, 77, 102, 0.1);
  border-radius: 8px;
  max-width: ${(props) => (props.horizontal ? '100%' : '100%')};
  background-color: #ffff;
  ${(props) =>
    props.horizontal
      ? css`
          height: 220px;
        `
      : css`
          min-height: 522px;
        `}'
`;

const StyledHead = styled.div<IHorizontal>`
  width: ${(props) => (props.horizontal ? '291px' : '100%')};
  border-radius: ${(props) =>
    props.horizontal ? '8px 0 0 8px' : '8px 8px 0 0'};
  height: ${(props) => (props.horizontal ? '100%' : '210px')};
  overflow: hidden;
`;

const StyledRating = styled.div<IHorizontal>`
  background-color: ${(props) => (props.horizontal ? '' : '#f5f5f5')};
  padding-left: ${(props) => (props.horizontal ? '' : '16px')};
  padding-top: ${(props) => (props.horizontal ? '' : '8px')};
  padding-bottom: ${(props) => (props.horizontal ? '' : '8px')};
  color: #424242;
`;

const StyledMainContainer = styled.div<IHorizontal>`
  padding: 16px;
  flex-grow: 1;
  ${(props) =>
    props.horizontal
      ? css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `
      : css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
`;
const StyledMain = styled.div<IHorizontal>`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${(props) => (props.horizontal ? '0px' : '16px')};
`;
const StyledFooter = styled.div<IHorizontal>`
  flex: 0;
  box-sizing: border-box;
  ${(props) =>
    props.horizontal
      ? css``
      : css`
          display: grid;
          grid-template-columns: 3fr repeat(
              ${props.gridButtons ? props.gridButtons : 0},
              1fr
            );
          grid-gap: 8px;
        `}
`;

const StyledMainTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledMainTop = styled.div``;
const StyledMainPriceContainer = styled.div``;

export type CardInfo = CardInfoProps;

interface CardProps {
  title: string;
  description: string;
  price: string;
  onClick?: () => void;
  authorInfo?: CardAuthorProps;
  infos?: CardInfo[];
  horizontal?: boolean;
  imagesIds: string[];
  rating?: number;
  fallbackImage?: string;
  endDate?: string;
  free?: VehicleStatus;
  tenderType?: number;
  measure?: Measure | null;
  actions?: ReactNode[];
  hideThumbnail?: boolean;
  publishDate?: string;
}

function Card(props: CardProps) {
  const {
    title,
    description,
    price,
    authorInfo,
    onClick,
    infos,
    horizontal = false,
    imagesIds,
    rating = null,
    fallbackImage,
    endDate,
    free,
    tenderType,
    measure,
    actions,
    hideThumbnail = false,
    publishDate,
  } = props;

  const onClickCard = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!onClick) {
        return;
      }

      onClick();
    },
    [onClick]
  );

  return (
    <StyledCardContainer className="smr-card" horizontal={horizontal}>
      {!hideThumbnail && (
        <StyledHead className="smr-card__head" horizontal={horizontal}>
          {(endDate || free) && !horizontal && (
            <CardStatus timer={endDate} free={free} />
          )}
          <CardThumb
            onClick={onClickCard}
            images={imagesIds}
            placeholder={fallbackImage}
          />
        </StyledHead>
      )}

      {!horizontal && rating && (
        <StyledRating className="smr-card__rating" horizontal={horizontal}>
          Рейтинг
          <StarRating rating={rating} reverse={false} marginSize={15} />
        </StyledRating>
      )}

      <StyledMainContainer
        className="smr-card__container"
        horizontal={horizontal}
      >
        <StyledMain
          className="smr-card__main"
          horizontal={horizontal}
          onClick={onClickCard}
        >
          <StyledMainTopContainer>
            <StyledMainTop>
              <CardTitle text={title} horizontal={horizontal} />
              {horizontal && (
                <StyledRating
                  className="smr-card__rating"
                  horizontal={horizontal}
                >
                  {rating && (
                    <>
                      Рейтинг
                      <StarRating
                        rating={rating}
                        reverse={false}
                        marginSize={14}
                      />
                    </>
                  )}
                </StyledRating>
              )}
              {!!infos && (
                <>
                  {infos.map((info, infoIndex) => (
                    <CardInfoComponent
                      {...info}
                      key={infoIndex.toString()}
                      horizontal={horizontal}
                    />
                  ))}
                </>
              )}
              {!horizontal && <CardDescription text={description} />}
              {horizontal && (
                <CardDescription text={description} horizontal={horizontal} />
              )}
            </StyledMainTop>
          </StyledMainTopContainer>
          <StyledMainPriceContainer>
            {!horizontal && (
              <CardPrice
                text={price}
                tenderType={tenderType}
                horizontal={horizontal}
                measure={measure}
              />
            )}
            {!horizontal && !!authorInfo && <CardAuthor {...authorInfo} />}
          </StyledMainPriceContainer>
        </StyledMain>
        <StyledFooter
          className="smr-card__footer"
          horizontal={horizontal}
          gridButtons={actions?.length}
        >
          {!horizontal && (
            <Button
              text="Подробнее"
              onClick={onClickCard}
              className={'smr-card__footer-btn'}
            />
          )}
          {!horizontal && (
            <>
              {actions?.map((action, actionIndex) => (
                <Fragment key={actionIndex}>{action}</Fragment>
              ))}
            </>
          )}

          {horizontal && (
            <CardInlineLeft
              free={free}
              endDate={endDate}
              price={price}
              tenderType={tenderType}
              actions={actions}
              publishDate={publishDate}
            />
          )}
        </StyledFooter>
      </StyledMainContainer>
    </StyledCardContainer>
  );
}

export default memo(Card);
