import styled from 'styled-components';
import ListItems from './components/ListItems';
import { memo } from 'react';
import { LayoutDefault } from '@app/layouts';
import { Container } from '@app/components';

const StyledPrivacyContainer = styled.section``;

const StyledPrivacyTitle = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: #0a0a0a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px auto 0;
`;

const StylesSubTitle = styled.div`
  padding: 16px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 64px;
`;

const PagePrivacy = () => {
  return (
    <LayoutDefault>
      <Container>
        <StyledPrivacyContainer>
          <StyledPrivacyTitle>Политика конфиденциальности</StyledPrivacyTitle>
          <StylesSubTitle>
            Настоящее Положение об обработке персональных данных (далее –
            «Положение») издано и применяется ТОО «Bright Technologies» (далее –
            «Оператор») в соответствии с Законом Республики Казахстан от
            21.05.2013 №94-V «О персональных данных и их защите» и определяет
            основные положения, реализуемые при обработке персональных данных
            Оператором. Целью настоящего Положения является выполнение
            требований законодательства в области защиты персональных данных,
            основанного на Конституции Республики Казахстан и состоящего из
            Закона Республики Казахстан от 21.05.2013 №94-V «О персональных
            данных и их защите» и иных нормативных правовых актов, определяющих
            случаи и особенности обработки персональных данных.
          </StylesSubTitle>
          <ListItems />
        </StyledPrivacyContainer>
      </Container>
    </LayoutDefault>
  );
};

export default memo(PagePrivacy);
