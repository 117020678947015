import React, { memo } from 'react';
import styled from 'styled-components';
import { IconClear } from '@app/icons';
import { Oked } from '@app/models';

interface Props {
  list: Oked[];
  onClickClear?: (number: number) => void;
}

const OkedsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-top: -16px;
`;

const OkedStyled = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  height: min-content;
  gap: 10px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-left: 16px;

  p {
    height: min-content;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0a0a0a;
    max-width: 500px;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    p {
      display: block;
      overflow: auto;
    }
  }

  svg {
    cursor: pointer;
  }
`;

function StyledOkedListBlock({ list, onClickClear }: Props) {
  return (
    <OkedsGrid>
      {list?.map((item, index) => {
        return (
          <OkedStyled key={index}>
            <p>{item.nameRu}</p>
            {!!onClickClear && (
              <IconClear onClick={() => onClickClear(index)} />
            )}
          </OkedStyled>
        );
      })}
    </OkedsGrid>
  );
}

export default memo(StyledOkedListBlock);
