import React, { memo, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  IconMobileAdd,
  IconMobileFavorite,
  IconMobileMain,
  IconMobileMenu,
  IconMobileNotification,
} from '@app/icons';
import { useUserContext } from '@app/providers';
import { NotificationContainer } from '@app/components';
import { ProfilesCard } from '@app/common';
import { ModalTenderCreate } from '@app/modals';

const MobileMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user, company, showSignInModal } = useUserContext();

  const [profileDetailsVisible, setProfileDetailsVisible] =
    useState<boolean>(false);
  const [modalTenderCreateVisible, setModalTenderCreateVisible] =
    useState<boolean>(false);
  const [notificationsVisible, setNotificationsVisible] =
    useState<boolean>(false);

  const onClickCloseNotification = useCallback(() => {
    setNotificationsVisible(false);
  }, [setNotificationsVisible]);

  const closeModalTenderCreate = useCallback(() => {
    setModalTenderCreateVisible(false);
  }, [setModalTenderCreateVisible]);

  const onClickProfile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setNotificationsVisible(false);

      setProfileDetailsVisible(
        (prevProfileDetailsVisible) => !prevProfileDetailsVisible
      );
    },
    [setNotificationsVisible, setProfileDetailsVisible]
  );

  const handleClickMain = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleClickFavorites = useCallback(() => {
    navigate('/profile/favorites');
  }, [navigate]);

  const handleClickCreateTender = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (user.authenticated) {
        setModalTenderCreateVisible(true);
        return;
      }

      showSignInModal();
    },
    [setModalTenderCreateVisible, showSignInModal, user.authenticated]
  );

  const handleClickNotification = useCallback(() => {
    setProfileDetailsVisible(false);
    setNotificationsVisible((notificationsVisible) => !notificationsVisible);
  }, [setNotificationsVisible, setProfileDetailsVisible]);

  return (
    <>
      <Menu>
        <MenuList>
          <MenuItem
            onClick={handleClickMain}
            isActive={
              pathname === '/' &&
              !notificationsVisible &&
              !modalTenderCreateVisible
            }
          >
            <MenuIcon>
              <IconMobileMain />
            </MenuIcon>
            <MenuText>Главная</MenuText>
          </MenuItem>
          {user.authenticated && (
            <MenuItem
              onClick={handleClickNotification}
              isActive={notificationsVisible}
            >
              <MenuIcon>
                <IconMobileNotification />
              </MenuIcon>
              <MenuText>Уведомления</MenuText>
              {notificationsVisible && (
                <NotificationContainer onClose={onClickCloseNotification} />
              )}
            </MenuItem>
          )}
          <MenuItem
            onClick={handleClickCreateTender}
            isActive={modalTenderCreateVisible}
          >
            <MenuAdd>
              <MenuIcon>
                <IconMobileAdd />
              </MenuIcon>
            </MenuAdd>
          </MenuItem>
          <MenuItem
            onClick={handleClickFavorites}
            isActive={pathname === '/profile'}
          >
            <MenuIcon>
              <IconMobileFavorite />
            </MenuIcon>
            <MenuText>Избранное</MenuText>
          </MenuItem>
          {user.authenticated && (
            <MenuItem onClick={onClickProfile} isActive={profileDetailsVisible}>
              <MenuItem isActive={profileDetailsVisible}>
                <MenuIcon>
                  <IconMobileMenu />
                </MenuIcon>
                <MenuText>Меню</MenuText>
              </MenuItem>
              {profileDetailsVisible && (
                <ProfilesCard
                  setProfileDetailsVisible={setProfileDetailsVisible}
                />
              )}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <ModalTenderCreate
        companyId={!!company ? company.id : null}
        visible={modalTenderCreateVisible}
        closeModal={closeModalTenderCreate}
      />
    </>
  );
};

const Menu = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: white;
  bottom: 0;
  left: 0;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 640px) {
    display: block;
  }
`;

const MenuList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 500;
  color: #9e9e9e;
  justify-content: space-around;
  list-style: none;
  margin-top: 8px;
`;

const MenuItem = styled.li<{ isActive: boolean }>`
  cursor: pointer;

  color: ${(props) => (props.isActive ? '#3a57e8' : '#9E9E9E')};

  & > div > svg > path {
    stroke: ${(props) => (props.isActive ? '#3a57e8' : '#757575')};
  }
`;
const MenuIcon = styled.div`
  display: flex;
  justify-content: center;
`;
const MenuText = styled.div``;
const MenuAdd = styled.div`
  background-color: #3a57e8;
  border-radius: 50%;
  padding: 17px;
  position: relative;
  top: -30%;
  border: 5px solid white;
`;

export default memo(MobileMenu);
