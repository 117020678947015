import React, { memo } from 'react';
import { LayoutDefault } from '@app/layouts';
import styled from 'styled-components';
import { Container, Paper, StyledContainer } from '@app/components';
import sectionBGImage from './bg.jpg';
import coreImage from './3.png';
import questionsImage from './2.avif';
import postImg from './9.png';
import searchImg from './4.png';
import img1 from './imgs/1.png';
import img2 from './imgs/2.png';
import img3 from './imgs/3.png';
import img4 from './imgs/4.png';
import img5 from './imgs/5.avif';
import functionalityImage from './functionality.jpg';
import { IconCheckCircle24, IconEmail16, IconPhone16 } from '@app/icons';
import './styles.scss';

const StyledSection = styled.section`
  padding: 32px 0;
  box-sizing: border-box;
`;

const StyledHeadingText = styled.p<{
  offsetBottom?: boolean;
  small?: boolean;
}>`
  font-weight: 700;
  font-size: ${(props) => (!!props.small ? '18px' : '24px')};
  line-height: 120%;
  color: #0a0a0a;
  margin: 0 0 ${(props) => (!!props.offsetBottom ? '32px' : '0')} 0;
`;

const StyledText = styled.p<{
  small?: boolean;
}>`
  font-size: ${(props) => (!!props.small ? '14px' : '18px')};
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.02em;
`;

const StyledSectionAbout = styled(StyledSection)`
  padding: 0;
  position: relative;
  margin-top: -16px;
`;

const StyledSectionAboutContainer = styled(StyledContainer)`
  display: flex;
  align-items: center;
  min-height: 500px;

  > p {
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 2;
    color: #fff;
    line-height: 32px;
  }

  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    background-image: url(${sectionBGImage});
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 0 72%;
    background-size: cover;
    z-index: 1;

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const StyledSectionCoreSection = styled(StyledSection)`
  a {
    display: block;
    color: #0a0a0a;
    margin-bottom: 8px;
    font-size: 16px;
    text-decoration: none;

    span {
      margin-left: 8px;
    }

    &:hover {
      span {
        color: #3a57e8;
      }
    }
  }
`;

const StyledSectionCoreContainer = styled.div`
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  max-width: 1144px;
  margin: 0 auto;
  gap: 32px;

  img {
    height: 300px;
  }

  ${StyledHeadingText} {
    margin-top: 40px;
  }
`;

const SectionFunctionality = styled.div`
  position: relative;
  min-height: 400px;

  > div:first-child {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  > div:nth-child(2) {
    padding-top: 16px;
    padding-bottom: 32px;
  }
`;

const StyledFunctionalityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const StyledFunctionalityImg = styled.img`
  width: 100px;
  margin-top: 16px;

  + p {
    margin: 16px 0;
    font-size: 16px;
    font-weight: 500;
  }
`;

const StyledTextWithCheck = styled.p`
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }
`;

const StyledSectionSolutionsContainer = styled.div`
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 1144px;
  margin: 0 auto;
  gap: 32px;
`;

const StyledSectionSolutionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
  margin-top: 32px;

  img {
    margin: 0 auto;
    height: 50px;
  }

  div {
    text-align: center;
  }

  p {
    margin: 8px 0 0 0;
    font-size: 12px;
    text-transform: uppercase;
  }
`;

function AboutUs() {
  return (
    <LayoutDefault className="smr-about-us">
      <StyledSectionAbout>
        <StyledSectionAboutContainer>
          <StyledHeadingText>
            Мы - молодая стартап ĸоманда, имеющая опыт в строительной индустрии.
            <br />
            Наша цель - реализовать цифровой проеĸт, ĸоторый упростит и усĸорит
            процессы в данной отрасли. Мы работаем на результат, воплощаем наши
            идеи в жизнь и стремимся ĸ постоянному развитию.
          </StyledHeadingText>
          <div />
        </StyledSectionAboutContainer>
      </StyledSectionAbout>

      <StyledSectionCoreSection>
        <StyledSectionCoreContainer>
          <div>
            <img src={coreImage} alt="" />
          </div>
          <div>
            <StyledHeadingText offsetBottom={true}>
              Суть проекта
            </StyledHeadingText>
            <StyledText>
              «Создание портала для партнёрсĸих взаимоотношений в сфере
              строительной индустрии, в формате «Единого оĸна», все в пару
              ĸлиĸов".
            </StyledText>
          </div>
        </StyledSectionCoreContainer>
      </StyledSectionCoreSection>

      <SectionFunctionality>
        <div>
          <img src={functionalityImage} alt="" />
        </div>
        <Container>
          <StyledFunctionalityGrid>
            <div />
            <div style={{ paddingLeft: 16 }}>
              <StyledHeadingText>Фунĸционал</StyledHeadingText>
              <StyledFunctionalityGrid>
                <div>
                  <StyledFunctionalityImg src={searchImg} />
                  <StyledText>Найти</StyledText>
                  <StyledTextWithCheck>
                    <IconCheckCircle24 />
                    субподрядные организаций для выполнения
                    строительно-монтажных работ
                  </StyledTextWithCheck>
                  <StyledTextWithCheck>
                    <IconCheckCircle24 />
                    строительно-монтажные объемы работ
                  </StyledTextWithCheck>
                  <StyledTextWithCheck>
                    <IconCheckCircle24 />
                    строительные компаний
                  </StyledTextWithCheck>
                  <StyledTextWithCheck>
                    <IconCheckCircle24 />
                    производителей строительных материалов
                  </StyledTextWithCheck>
                </div>
                <div>
                  <StyledFunctionalityImg src={postImg} />
                  <StyledText>Объявить</StyledText>
                  <StyledTextWithCheck>
                    <IconCheckCircle24 />
                    спецтехнику
                  </StyledTextWithCheck>
                  <StyledTextWithCheck>
                    <IconCheckCircle24 />
                    аутсорсинговые «кадров из сферы строительной индустрии»
                  </StyledTextWithCheck>
                </div>
              </StyledFunctionalityGrid>
            </div>
          </StyledFunctionalityGrid>
        </Container>
      </SectionFunctionality>
      <StyledSection>
        <StyledSectionSolutionsContainer>
          <div>
            <StyledHeadingText>На площадĸе зарегистрированы</StyledHeadingText>
            <StyledSectionSolutionsGrid>
              <Paper shadow={true}>
                <img src={img5} alt="" />
                <p>генподрядчики</p>
              </Paper>
              <Paper shadow={true}>
                <img src={img1} alt="" />
                <p>субподрядчики</p>
              </Paper>
              <Paper shadow={true}>
                <img src={img2} alt="" />
                <p>производители строительных материалов</p>
              </Paper>
              <Paper shadow={true}>
                <img src={img3} alt="" />
                <p>владельцы спецтехники</p>
              </Paper>
              <Paper shadow={true}>
                <img src={img4} alt="" />
                <p>аутсорс кадры из строительной индустриии</p>
              </Paper>
            </StyledSectionSolutionsGrid>
          </div>
        </StyledSectionSolutionsContainer>
      </StyledSection>
      <StyledSectionCoreSection>
        <StyledSectionCoreContainer>
          <div>
            <img src={questionsImage} alt="" />
          </div>
          <div>
            <StyledHeadingText offsetBottom={true}>
              Остались вопросы?
            </StyledHeadingText>
            <div>
              <div>
                <a href="tel:+77073326970">
                  <IconPhone16 /> <span>+7 (777) 111 13 36</span>
                </a>
              </div>
              <div>
                <a
                  href="mailto:1brightech@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconEmail16 /> <span>1brightech@gmail.com </span>
                </a>
              </div>
            </div>
          </div>
        </StyledSectionCoreContainer>
      </StyledSectionCoreSection>
    </LayoutDefault>
  );
}

export default memo(AboutUs);
