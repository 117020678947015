import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Doc, Reply, ReplyStatus, UserType } from '@app/models';
import styled from 'styled-components';
import { Button, Info, Loader, Paper } from '@app/components';
import { currencyFormat, dateFormat } from '@app/helpers';
import {
  acceptReply,
  declineReply,
  getContracts,
  getReplyById,
  pinnedReply,
  unpinnedReply,
} from '@app/api';
import { AuthorSection } from '../index';
import { useNotification, useUserContext } from '@app/providers';
import {
  IconCheckCircle16,
  IconCheckCircle24,
  IconCloseCircle16,
  IconCloseCircle24,
  IconFlag,
} from '@app/icons';
import { ModalAddPartner, ModalConfirm, ModalReport } from '@app/modals';
import { useNavigate } from 'react-router-dom';
import Chat from '../Chat';

interface Props {
  reply: Reply;
  onUpdate: (reply: Reply) => void;
}

const StyledReply = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledInfoGrid = styled.div`
  display: grid;
  grid-gap: 16px;

  &::-webkit-scrollbar {
    width: 2px;
    /* padding: 10px; */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a57e8;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #3a57e8;
  }
`;

const StyledGridBlock = styled.div<{ isChat: boolean }>`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: ${(props) => (props.isChat ? '3fr 2fr' : '1fr')};
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: 16px;
  }
`;

function ReplyDetail(props: Props) {
  const { reply: propsReply, onUpdate } = props;
  const { user, company } = useUserContext();
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [accepting, setAccepting] = useState<boolean>(false);
  const [declining, setDeclining] = useState<boolean>(false);
  const [pining, setPining] = useState<boolean>(false);
  const [reply, setReply] = useState<Reply | null>(null);
  const [hasContract, setHasContract] = useState<boolean>(false);
  const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);
  const [createContractModalVisible, setCreateContractModalVisible] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [modalConfirmVisible, setModalConfirmVisible] =
    useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      const response = await getReplyById(propsReply.id);
      const contractsParams: Partial<Doc> = {};

      if (user.type === UserType.ENTITY) {
        contractsParams.companyId = company!.id;
      } else {
        contractsParams.ownerId = user.id;
      }

      if (!!response.companyId) {
        contractsParams.recipientCompanyId = response.companyId;
      } else {
        contractsParams.recipientId = response.ownerId;
      }

      if (!!response.tenderId) {
        contractsParams.tenderId = response.tenderId;
      }

      const contracts = await getContracts({
        page: 1,
        perPage: 1,
        ...contractsParams,
      });

      if (contracts.data.length > 0) {
        setHasContract(true);
      }

      setReply(response);
      setError(false);
      setPending(false);
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [company, propsReply.id, user.id, user.type]);

  const onClickPinned = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        setPining(true);

        const response = await pinnedReply(propsReply.id);

        onUpdate(response);
        setReply(response);

        setPining(false);

        showNotification({
          message: 'Отклик помечен',
          variant: 'success',
        });
      } catch (e) {
        setPining(false);
      }
    },
    [onUpdate, propsReply.id, showNotification]
  );

  const onClickUnpinned = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        setPining(true);

        const response = await unpinnedReply(propsReply.id);

        onUpdate(response);
        setReply(response);

        setPining(false);

        showNotification({
          message: 'Отклик откреплен',
          variant: 'success',
        });
      } catch (e) {
        setPining(false);
      }
    },
    [onUpdate, propsReply.id, showNotification]
  );

  const onClickDecline = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        setDeclining(true);

        const response = await declineReply(propsReply.id);

        onUpdate(response);
        setReply(response);

        setDeclining(false);

        showNotification({
          message: 'Отклик отклонен',
          variant: 'success',
        });
      } catch (e) {
        setDeclining(false);
      }
    },
    [onUpdate, propsReply.id, showNotification]
  );

  const onClickAccept = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        setAccepting(true);

        const response = await acceptReply(propsReply.id);

        onUpdate(response);
        setReply(response);

        setAccepting(false);

        showNotification({
          message: 'Отклик принять',
          variant: 'success',
        });
      } catch (e) {
        setAccepting(false);
      }
    },
    [onUpdate, propsReply.id, showNotification]
  );

  const onClickGoToTender = useCallback(() => {
    if (reply)
      navigate(
        `${user.id !== reply.ownerId ? '/profile' : ''}/tender/${
          reply.tenderId
        }`
      );
  }, [navigate, reply, user.id]);

  const onClickReport = useCallback(() => {
    setReportModalVisible(true);
  }, []);

  const onCloseReportModal = useCallback(() => {
    setReportModalVisible(false);
  }, []);

  const onClickAcceptButton = useCallback(() => {
    if (user.type === UserType.INDIVIDUAL && !user.iin) {
      setModalConfirmVisible(true);

      return;
    }
    setCreateContractModalVisible(true);
  }, [user]);

  const onCloseModalConfirm = useCallback(() => {
    setModalConfirmVisible(false);
  }, []);

  const onClickModalConfirm = useCallback(() => {
    navigate('/profile/edit');
  }, [navigate]);

  const onCloseContractModal = useCallback(() => {
    setCreateContractModalVisible(false);
  }, []);

  const onSuccessAddContract = useCallback(() => {
    setHasContract(true);
    setCreateContractModalVisible(false);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const authorCompany = useMemo(() => {
    try {
      return reply?.owner?.companies?.find(
        (val) => val.id === reply?.companyId
      );
    } catch (e) {
      return null;
    }
  }, [reply]);

  return (
    <StyledReply>
      <StyledGridBlock isChat={!!reply?.chat.id}>
        <Paper title="Информация">
          <StyledInfoGrid>
            <Info
              label="Дата подачи"
              value={dateFormat(propsReply.createdAt)}
            />
            <Info label="Цена" value={currencyFormat(propsReply.price)} />
            <Info label="Комментарий" value={propsReply.description} />
          </StyledInfoGrid>
          {!pending && !!reply && (
            <Paper title="Требования">
              <StyledInfoGrid
                style={{
                  maxHeight: '200px',
                  overflow: 'auto',
                }}
              >
                {reply.requirements.map((requirement) => (
                  <Info
                    icon={
                      requirement.checked
                        ? IconCheckCircle24
                        : IconCloseCircle24
                    }
                    label={requirement.requirement.name}
                    value={requirement.comment || '-'}
                    key={requirement.id}
                  />
                ))}
              </StyledInfoGrid>
            </Paper>
          )}
        </Paper>
        {reply?.chat.id && <Chat chatId={reply?.chat.id} />}
      </StyledGridBlock>
      {pending && <Loader />}
      {!pending && !!reply && (
        <>
          {!!reply.fileIds && <Paper title="Документы и Файлы" />}
          <AuthorSection
            className="page-tender__author"
            author={reply.owner}
            company={authorCompany}
            showContacts={false}
          />
          <StyledFooter>
            {reply.status === ReplyStatus.NEW && (
              <>
                {reply.pinned ? (
                  <Button
                    icon={IconFlag}
                    text="Открепить"
                    disabled={accepting || declining || pining}
                    loading={declining}
                    onClick={onClickUnpinned}
                    color="default"
                  />
                ) : (
                  <Button
                    icon={IconFlag}
                    text="Пометить"
                    disabled={accepting || declining || pining}
                    loading={declining}
                    onClick={onClickPinned}
                    color="default"
                  />
                )}
                {/*                {user.id === reply.ownerId && (
                  <Button
                    text="Пожаловаться"
                    color="danger"
                    onClick={onClickReport}
                  />
                )}*/}
                {user.id !== reply.ownerId && (
                  <>
                    <Button
                      icon={IconCloseCircle16}
                      text="Отклонить"
                      disabled={declining || pining}
                      loading={declining}
                      onClick={onClickDecline}
                      color="default"
                    />
                    <Button
                      icon={IconCheckCircle16}
                      text="Принять"
                      disabled={accepting || pining}
                      loading={accepting}
                      onClick={onClickAccept}
                    />
                  </>
                )}
              </>
            )}
            {reply.status === ReplyStatus.ACCEPT && !hasContract && (
              <Button
                icon={IconCheckCircle16}
                text="Заключить договор"
                disabled={declining || pining}
                loading={accepting}
                color="default"
                onClick={onClickAcceptButton}
              />
            )}
            <Button text="Перейти в объявление" onClick={onClickGoToTender} />
          </StyledFooter>
        </>
      )}
      {reply && (
        <>
          <ModalReport
            visible={reportModalVisible}
            onClose={onCloseReportModal}
          />
          {reply.status === ReplyStatus.ACCEPT && !hasContract && (
            <>
              <ModalAddPartner
                currentStep={1}
                recipientUser={!reply.companyId ? reply.owner! : null}
                recipientCompany={!!reply.companyId ? reply.company : null}
                visible={createContractModalVisible}
                onClose={onCloseContractModal}
                userType={user.type}
                companyId={user.type === UserType.ENTITY ? company!.id : null}
                onSuccess={onSuccessAddContract}
              />
              <ModalConfirm
                onAccept={onClickModalConfirm}
                title={'Заполните ИИН в профиле'}
                textButtonCancel={'Закрыть'}
                textButtonSuccess={'Добавить ИИН в профиль'}
                visible={modalConfirmVisible}
                onClose={onCloseModalConfirm}
                skipSuccessStep={true}
                subText={
                  'Для заключения договоров с партнерами необходимо заполнить ИИН в профиле'
                }
              />
            </>
          )}
        </>
      )}
    </StyledReply>
  );
}

export default memo(ReplyDetail);
