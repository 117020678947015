import { IconChevronLeft } from '@app/icons';
import { memo, PropsWithChildren, useRef, useState } from 'react';
import classNames from 'classnames';
import './styles.scss';

interface Props extends PropsWithChildren {
  label: string;
}

const Collapse = (props: Props) => {
  const [open, setOPen] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const toggle = () => {
    setOPen(!open);
  };

  return (
    <div>
      <button onClick={toggle} className="button">
        {props.label}
        <div className={classNames('icon', { 'icon-rotate': open })}>
          <IconChevronLeft />
        </div>
      </button>
      <div
        className="content-parent"
        ref={contentRef}
        style={
          open
            ? { height: contentRef?.current?.scrollHeight + 'px' }
            : { height: '0px' }
        }
      >
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
};

export default memo(Collapse);
