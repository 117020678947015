import React, { Component, memo } from 'react';
import { Modal, ModalProps, Stepper } from '@app/components';
import { Step1, Step3 } from './components';
import { Company, Doc, DocTypes, User, UserType } from '@app/models';
import { FilePickerForm, FormDoc } from '@app/forms';
import { createDoc } from '@app/api';
import styled from 'styled-components';

interface Props extends Partial<ModalProps> {
  currentStep?: number;
  recipientUser?: Pick<User, 'id'> | null;
  recipientCompany?: Pick<Company, 'id'> | null;
  userType: UserType;
  companyId: string | null;
  onSuccess?: (doc: Doc) => void;
}

interface State {
  company: Pick<Company, 'id'> | null;
  user: Pick<User, 'id'> | null;
  doc: Partial<Doc> | null;
  currentStep: number;
  maxCurrentStep: number;
  tenderId: string | null;
  pending: boolean;
  files: File[];
}

const StyledStepper = styled.div`
  background-color: theme('color.white');
  margin: -16px -16px 16px -16px;
`;

class ModalAddPartner extends Component<Props, State> {
  steps = [
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 0 ? 0 : currentStep,
        }));
      },
      title: 'Выберите партнера',
    },
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 1 ? 1 : currentStep,
        }));
      },
      title: 'Заполните данные договора',
    },
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 2 ? 2 : currentStep,
        }));
      },
      title: 'Выберите объявление (необязательно)',
    },
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 3 ? 3 : currentStep,
        }));
      },
      title: 'Загрузить документ',
    },
  ];

  state: State = {
    company: this.props.recipientCompany || null,
    user: this.props.recipientUser || null,
    doc: null,
    currentStep: this.props.currentStep || 0,
    maxCurrentStep: this.props.currentStep || 0,
    tenderId: null,
    pending: false,
    files: [],
  };

  onClickClose = () => {
    const { onClose } = this.props;

    if (!!onClose) {
      onClose();
    }
  };

  onSubmitCompany = (company: Company | null, user: User | null) => {
    this.setState(({ maxCurrentStep }) => ({
      company,
      user,
      currentStep: 1,
      maxCurrentStep: maxCurrentStep <= 1 ? 1 : maxCurrentStep,
    }));
  };

  onSubmitDoc = (doc: Partial<Doc>) => {
    this.setState(({ maxCurrentStep }) => ({
      doc,
      currentStep: 2,
      maxCurrentStep: maxCurrentStep <= 2 ? 2 : maxCurrentStep,
    }));
  };

  onSubmitTender = (tenderId: string | null) => {
    this.setState(({ maxCurrentStep }) => ({
      tenderId,
      currentStep: 3,
      maxCurrentStep: maxCurrentStep <= 3 ? 3 : maxCurrentStep,
    }));
  };

  onSubmitFiles = (files: File[]) => {
    this.setState(
      {
        files,
        pending: true,
      },
      async () => {
        await this.createPartner();
      }
    );
  };

  createPartner = async () => {
    try {
      const { userType, companyId, onSuccess } = this.props;
      const { company, user, tenderId, files, doc } = this.state;
      const params: Partial<Doc> = {
        ...doc,
      };

      if (!!company) {
        params.recipientCompanyId = company.id;
      }

      if (!!user) {
        params.recipientId = user.id;
      }

      if (userType === UserType.ENTITY) {
        params.companyId = companyId!;
      }

      if (!!tenderId) {
        params.tenderId = tenderId;
      }

      const docResponse = await createDoc(params, files);

      this.setState(
        {
          company: null,
          doc: null,
          currentStep: 0,
          maxCurrentStep: 0,
          tenderId: null,
          pending: false,
          files: [],
        },
        () => {
          onSuccess!(docResponse);
        }
      );
    } catch (e) {
      this.setState({
        pending: false,
      });
    }
  };

  render() {
    const { visible } = this.props;
    const { currentStep, maxCurrentStep, pending } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <Modal
        visible={visible}
        size="xlarge"
        contentStyles={currentStep === 1 ? { minHeight: '500px' } : {}}
      >
        <StyledStepper>
          <Stepper
            onClose={this.onClickClose}
            title="Заключить договор с новым партнером"
            steps={this.steps}
            currentStep={currentStep}
            maxCurrentStep={maxCurrentStep}
            fullWidth={true}
          />
        </StyledStepper>
        {currentStep === 0 && <Step1 onSubmit={this.onSubmitCompany} />}
        {currentStep === 1 && (
          <FormDoc onSubmit={this.onSubmitDoc} type={DocTypes.CONTRACT} />
        )}
        {currentStep === 2 && <Step3 onSubmit={this.onSubmitTender} />}
        {currentStep === 3 && (
          <FilePickerForm
            accept="application/pdf"
            pending={pending}
            defaultExpanded={currentStep === 3}
            onSubmit={this.onSubmitFiles}
            required={true}
          />
        )}
      </Modal>
    );
  }
}

export default memo(ModalAddPartner);
