import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

import { TenderTypeNames, TenderTypes } from '@app/models';
import { ExpandableForm } from '@app/common';
import Panel1Img from './1.svg';
import Panel2Img from './2.svg';

interface Props {
  disableSwitcher?: boolean;
  defaultExpanded: boolean;
  value: TenderTypes | null;
  onSubmit: (tenderType: TenderTypes, isSubmit: boolean) => void;
}

const StyledContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 768px) {
    align-items: initial;
    display: flex;
    flex-direction: column;
  }
`;

const StyledCheckBox = styled.div`
  padding: 8px;
  width: 192px;
  background-color: #f5f5f5;
  border-radius: 10.6327px;

  p {
    margin: 0 0 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
  img {
    height: 90px;
  }
  &:hover {
    cursor: pointer;
    border: 2px solid #3a57e8;
    padding: 6px;
  }
  &.checked {
    border: 2px solid #3a57e8;
    padding: 6px;
  }
  &.disable {
    display: none;
  }
`;

function TenderTypeForm(props: Props) {
  const { defaultExpanded, disableSwitcher, value, onSubmit } = props;
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [checkedItem, setCheckedItem] = useState(0);

  const onChange = useCallback(
    (tenderType: TenderTypes) => () => {
      setCheckedItem(tenderType);
      onSubmit(tenderType, false);
    },
    [onSubmit]
  );

  const onChangeSwitch = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setExpanded(false);
      onSubmit(value as TenderTypes, true);
    },
    [onSubmit, value]
  );

  return (
    <ExpandableForm
      submitLabel="Далее"
      title="Выберите тип объявления"
      onSubmit={onSubmitForm}
      description={value ? TenderTypeNames[value] : ''}
      expanded={expanded}
      disableSwitcher={disableSwitcher}
      onChangeSwitch={onChangeSwitch}
      disabled={!value || !expanded}
    >
      <StyledContainer>
        <StyledCheckBox
          className={`
            ${checkedItem === TenderTypes.OFFER ? 'checked' : ''} 
          `}
          onClick={onChange(TenderTypes.OFFER)}
        >
          <p>Предлогаю</p>
          {/*<p>{TenderTypeNames[TenderTypes.OFFER]}</p>*/}
          <div>
            <img src={Panel1Img} alt={TenderTypeNames[TenderTypes.OFFER]} />
          </div>
        </StyledCheckBox>
        <StyledCheckBox
          className={`
            ${checkedItem === TenderTypes.REQUEST ? 'checked' : ''} 
          `}
          onClick={onChange(TenderTypes.REQUEST)}
        >
          <p>Ищу</p>
          {/*<p>{TenderTypeNames[TenderTypes.REQUEST]}</p>*/}
          <div>
            <img src={Panel2Img} alt={TenderTypeNames[TenderTypes.REQUEST]} />
          </div>
        </StyledCheckBox>
      </StyledContainer>
    </ExpandableForm>
  );
}

export default memo(TenderTypeForm);
