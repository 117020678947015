import React, { memo, useCallback, useState } from 'react';
import { FilePicker } from '@app/components';
import { useNotification } from '@app/providers';
import { ExpandableForm } from '@app/common';

interface Props {
  multiple?: boolean;
  defaultExpanded: boolean;
  onSubmit: (files: File[]) => void;
  required?: boolean;
  pending: boolean;
  accept?: string;
}

function FilePickerForm(props: Props) {
  const {
    onSubmit,
    defaultExpanded,
    required = true,
    pending,
    multiple = false,
    accept,
  } = props;
  const { showNotification } = useNotification();
  const [files, setFiles] = useState<File[]>([]);
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  const onChangeFiles = useCallback((value: File[]) => {
    setFiles(value);
  }, []);

  const onChangeSwitch = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (required && files.length === 0) {
        showNotification({
          message: 'Выберите минимум 1 файл',
          variant: 'error',
        });

        return;
      }

      onSubmit(files);
    },
    [required, files, onSubmit, showNotification]
  );

  return (
    <ExpandableForm
      title="Документы и Файлы"
      onSubmit={onSubmitForm}
      expanded={expanded}
      onChangeSwitch={onChangeSwitch}
      disabled={(required && files.length === 0) || !expanded}
      loading={pending}
      disableSwitcher
    >
      <FilePicker
        value={files}
        onChange={onChangeFiles}
        accept={accept}
        multiple={multiple}
      />
    </ExpandableForm>
  );
}

export default memo(FilePickerForm);
