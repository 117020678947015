import React, { memo, useCallback, useEffect, useState } from 'react';
import { Company } from '@app/models';
import { getCompanyByBin } from '@app/api';
import { ProfileCompany, WelcomeBanner } from '@app/common';
import { Loader, StyledContainer } from '@app/components';
import { LayoutDefault } from '@app/layouts';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const StyledLoader = styled.div`
  text-align: center;
  margin: 16px 0;
`;

const Grid = styled(StyledContainer)`
  display: grid;
  grid-gap: 16px;
`;

function PageCompany() {
  const { bin } = useParams<'bin'>();
  const [error, setError] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(true);
  const [company, setCompany] = useState<Company | null>(null);

  const getData = useCallback(async () => {
    try {
      if (!bin) return;

      const response = await getCompanyByBin(bin as string);

      setCompany(response);
      setError(false);
      setPending(false);
    } catch (e) {
      setPending(false);
      setError(true);
    }
  }, [bin]);

  useEffect(() => {
    getData();
  }, []);

  if (pending) {
    return <Loader />;
  }

  return (
    <LayoutDefault>
      <WelcomeBanner />
      {pending && (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      )}
      <Grid>
        {company && <ProfileCompany company={company} editable={false} />}
      </Grid>
    </LayoutDefault>
  );
}

export default memo(PageCompany);
