import { memo, ReactNode, useCallback } from 'react';
import { Doc, DocStatus, UserType } from '@app/models';
import { RenderFilterProps, SearchTemplate } from '@app/templates';
import { getPartners, PageableParams } from '@app/api';
import CompaniesFilter, {
  CompaniesFilterData,
} from './components/CompaniesFilter';
import { Info } from '@app/components';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  fullName,
  getCompanyTypeShortName,
  getPartnerEntity,
  getPartnerIndividual,
} from '@app/helpers';
import { useUserContext } from '@app/providers';

interface Props {
  title: string;
  controls: ReactNode[];
}

const StyledCompany = styled.div`
  padding: 16px;
  box-sizing: border-box;
  gap: 24px;
  background: #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 150px repeat(4, 200px) 100px;
  align-items: center;
`;

function PartnersSearch(props: Props) {
  const { title, controls } = props;
  const { user, company } = useUserContext();
  const navigate = useNavigate();

  const onClickCompany = useCallback(
    (item: Doc) => () => {
      const partnerCompany = getPartnerEntity(item, company);

      if (!!partnerCompany) {
        navigate(`/profile/partners/${UserType.ENTITY}/${partnerCompany.id}`);

        return;
      }

      return navigate(
        `/profile/partners/${UserType.INDIVIDUAL}/${
          getPartnerIndividual(item, user.id)!.id
        }`
      );
    },
    [company, navigate, user.id]
  );

  const renderItem = useCallback(
    (item: Doc) => {
      const partnerCompany = getPartnerEntity(item, company);
      const partnerUser = getPartnerIndividual(item, user.id);

      return (
        <StyledCompany onClick={onClickCompany(item)}>
          {!!partnerCompany && (
            <>
              <Info
                label="БИН партнера"
                value={partnerCompany.bin}
                small={true}
              />
              <Info
                label="Наименование партнера"
                value={`${getCompanyTypeShortName(partnerCompany.type)} ${
                  partnerCompany.name
                }`}
                small={true}
                displayedLength={23}
              />
            </>
          )}
          {!!partnerUser && (
            <>
              <Info label="ИИН партнера" value={partnerUser.iin} small={true} />
              <Info
                label="Партнер"
                value={fullName(partnerUser)}
                small={true}
              />
            </>
          )}

          <Info
            label="Количество договоров"
            value={`${
              item.docs.filter((doc) => doc.status !== DocStatus.FINISHED)
                .length
            }/${item.docs.length}`}
            small={true}
          />
          {/*<Info label="Статус" value="Договор заключен" small={true} />*/}
          {/*<Info*/}
          {/*  label="Срок договора"*/}
          {/*  value={dateFormat(item.endDate)}*/}
          {/*  small={true}*/}
          {/*/>*/}
          <Info
            label="Регион"
            value={
              item.recipientCompanyId
                ? item.recipientCompany!.kato
                  ? item.recipientCompany!.kato.nameRu
                  : 'Не указан'
                : item.recipient!.kato
                ? item.recipient!.kato.nameRu
                : 'Не указан'
            }
            small={true}
          />
        </StyledCompany>
      );
    },
    [company, onClickCompany, user.id]
  );

  const getData = useCallback(
    (params: PageableParams, filter?: CompaniesFilterData) => {
      const defaultParams: Partial<Doc> = filter!;

      if (user.type === UserType.INDIVIDUAL) {
        defaultParams.ownerId = user.id;
      } else {
        defaultParams.companyId = company!.id;
      }

      return getPartners({
        ...params,
        ...defaultParams,
      });
    },
    [company, user]
  );

  const renderFilter = useCallback(
    (filterProps: RenderFilterProps<CompaniesFilterData>) => {
      return <CompaniesFilter {...filterProps} />;
    },
    []
  );

  return (
    <>
      <SearchTemplate<Doc, CompaniesFilterData>
        columns={1}
        renderItem={renderItem}
        getData={getData}
        renderFilter={renderFilter}
        title={title}
        controls={controls}
        variant="paper"
        viewControl={false}
        emptyTitle={
          <span>
            Тут появится список партнеров, с кем Вы заключили договор на{' '}
            <a href="https://smrkz.kz/" target="_blank" rel="noreferrer">
              https://smrkz.kz/
            </a>
          </span>
        }
      />
    </>
  );
}

export default memo(PartnersSearch);
