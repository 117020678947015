import React from 'react';
import styles from '../NotificationPage/Notification.module.scss';
import { Toolbar } from '@app/common';
import { ArcElement, Chart } from 'chart.js';
import { Pie } from 'react-chartjs-2';

Chart.register(ArcElement);

const users = [
  { id: 1, name: 'User1', userType: 'individual' },
  { id: 2, name: 'User2', userType: 'entity' },
  { id: 1, name: 'User1', userType: 'individual' },
  { id: 2, name: 'User2', userType: 'entity' },
  { id: 2, name: 'User2', userType: 'entity' },
  // Add more users as needed
];

const PageProfileDashboard = () => {
  // Separate users into individual and entity groups
  const individualUsers = users.filter(
    (user) => user.userType === 'individual'
  );
  const entityUsers = users.filter((user) => user.userType === 'entity');

  // Create data for the pie chart
  const data = {
    labels: [
      ...individualUsers.map((user) => user.name),
      ...entityUsers.map((user) => user.name),
    ],
    datasets: [
      {
        data: [
          ...individualUsers.map((user) => user.id),
          ...entityUsers.map((user) => user.id),
        ],
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4CAF50',
          '#FF5733',
        ], // Add more colors as needed
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, // To allow setting a fixed size
    responsive: false, // To disable responsiveness
    legend: {
      display: true,
      position: 'right', // You can change the position as needed
    },
  };

  return (
    <div className={styles.container}>
      <Toolbar
        {...{
          title: 'Дашборд',
        }}
      />
      <Pie data={data} width={250} height={250} options={options} />
    </div>
  );
};

export default PageProfileDashboard;
