import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  KatoSelectTrigger,
  Select,
  SelectOption,
  TextField,
} from '@app/components';
import { ExpandableForm } from '@app/common';
import * as yup from 'yup';
import { Kato, Measure, MeasureLabelsFull, Vehicle } from '@app/models';
import { useForm } from '../hooks';
import { useTranslation } from 'react-i18next';
import { mapRecordToSelectOption } from '@app/helpers';

interface VehicleCreateFormProps {
  defaultExpanded: boolean;
  onSubmit: (data: VehicleFormData) => void;
  vehicle?: Vehicle;
  setValues?: (values: Partial<VehicleFormValues>) => void;
  edit?: boolean;
  errors?: any;
}

export interface VehicleFormData {
  name: string;
  description: string;
  price: string;
  files: File[];
  kato: string;
  dateOfIssue: string;
  type: string;
  brand: string;
  measure: SelectOption<Measure> | null;
}

export interface VehicleFormValues extends Omit<VehicleFormData, 'kato'> {
  kato: Kato | null;
}

function VehicleCreateForm(props: VehicleCreateFormProps) {
  const {
    defaultExpanded,
    onSubmit,
    setValues: setValuesProps,
    vehicle,
    edit = false,
    errors: errorsProps,
  } = props;
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const { values, onChange, errors, validate, setValues, setErrors } =
    useForm<VehicleFormValues>({
      name: '',
      description: '',
      price: '',
      dateOfIssue: '',
      files: [],
      kato: null,
      type: '',
      brand: '',
      measure: null,
    });

  useEffect(() => {
    if (vehicle) {
      setValues({
        name: vehicle.name,
        description: vehicle.description,
        price: vehicle.price,
        dateOfIssue: vehicle.dateOfIssue,
        kato: vehicle.kato,
        type: vehicle.type,
        brand: vehicle.brand,
        measure: vehicle.measure
          ? {
              label: MeasureLabelsFull[vehicle.measure],
              value: +Measure[vehicle.measure],
            }
          : null,
      });
    }
  }, []);

  useEffect(() => {
    if (setValuesProps) {
      setValuesProps({
        name: values.name,
        description: values.description,
        price: values.price,
        dateOfIssue: values.dateOfIssue,
        type: values.type,
        brand: values.brand,
        kato: values.kato,
        measure: values.measure,
      });
    }
  }, [values]);

  useEffect(() => {
    if (errorsProps) {
      setErrors(errorsProps);
    }
  }, [errorsProps]);

  const onChangeSwitch = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onChangeKato = useCallback(
    (data: SelectOption<Kato>) => {
      onChange(data.value, 'kato');
    },
    [onChange]
  );

  const onSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const hasErrors = await validate(
        yup.object().shape({
          // name: yup.string().required(t('required', { ns: 'validation' })!),
          description: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
          brand: yup.string().required(t('required', { ns: 'validation' })!),
          type: yup.string().required(t('required', { ns: 'validation' })!),
          price: yup.string().required(t('required', { ns: 'validation' })!),
          kato: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
          dateOfIssue: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
        })
      );

      if (hasErrors) {
        return;
      }

      onSubmit({
        ...values,
        name: '',
        kato: values.kato!.code,
        measure: values.measure && values.measure,
      });

      setExpanded(false);
    },
    [t, onSubmit, validate, values]
  );

  const measureOptions = useMemo(() => {
    const measureArray = Object.values(Measure).filter((value) =>
      Number.isFinite(value)
    );
    return measureArray.map((measure) =>
      mapRecordToSelectOption(measure, MeasureLabelsFull)
    );
  }, []);

  return (
    <ExpandableForm
      submitLabel={'Далее'}
      title="Подать предложение"
      onSubmit={onSubmitForm}
      description=""
      expanded={expanded}
      onChangeSwitch={onChangeSwitch}
      disabled={!expanded}
      disableSwitcher
      viewHead={!edit}
      nullablePadding={edit}
      disableSubmitLabel={edit}
    >
      <Grid>
        <Grid columns={3}>
          {/*<TextField*/}
          {/*  value={values.name}*/}
          {/*  onChange={onChange}*/}
          {/*  name="name"*/}
          {/*  label="Наименование"*/}
          {/*  placeholder="Введите наименование"*/}
          {/*  error={!!errors.name}*/}
          {/*  helperText={errors.name}*/}
          {/*  required={true}*/}
          {/*/>*/}
          <TextField
            value={values.type}
            onChange={onChange}
            name="type"
            label="Тип"
            placeholder="Тип транспорта"
            error={!!errors.type}
            helperText={errors.type}
            required={true}
          />
          <TextField
            value={values.brand}
            onChange={onChange}
            name="brand"
            label="марка"
            placeholder="Марка транспорта"
            error={!!errors.brand}
            helperText={errors.brand}
            required={true}
          />
          <TextField
            label="год выпуска"
            placeholder="Выберите год"
            name="dateOfIssue"
            value={values.dateOfIssue}
            mask="####"
            onChange={onChange}
            helperText={errors.dateOfIssue}
            error={!!errors.dateOfIssue}
            required={true}
          />
        </Grid>
        <Grid columns={2}>
          <Grid columns={2}>
            <TextField
              value={values.price}
              onChange={onChange}
              name="price"
              label="Цена за час"
              placeholder="Введите цену"
              error={!!errors.price}
              helperText={errors.price}
              required={true}
            />
            <Select
              label="Еденицы измерения"
              value={values.measure}
              placeholder="Выберите ед. из."
              options={measureOptions}
              name="measure"
              onChange={onChange}
              helperText={errors.measure || ''}
              error={!!errors.measure}
              required={true}
            />
          </Grid>
          <KatoSelectTrigger
            label="Выберите регион"
            value={values.kato ? values.kato.nameRu : ''}
            onChange={onChangeKato}
            helperText={errors.kato}
            error={!!errors.kato}
            required={true}
          />
        </Grid>
        <TextField
          value={values.description}
          onChange={onChange}
          name="description"
          label="Краткое содержание"
          placeholder="Максимальная длина текста 2100 знаков. Осталось 2100 знаков."
          error={!!errors.description}
          helperText={errors.description}
          required={true}
        />
      </Grid>
    </ExpandableForm>
  );
}

export default memo(VehicleCreateForm);
