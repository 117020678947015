import React, { memo } from 'react';
import { ChatMessage, User } from '@app/models';
import styled from 'styled-components';
import { dateFormat, fullName } from '@app/helpers';
import { ProfileAvatar } from '../../../../pages/Profile/components';

interface Props {
  message: ChatMessage;
  user: User;
}

interface PropsForAuthor {
  isAuthor: boolean;
}

const StyledMessage = styled.div<PropsForAuthor>`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: ${(props) => (props.isAuthor ? 'row-reverse' : 'row')};
  margin-left: ${(props) => (props.isAuthor ? 'auto' : '0')};
`;

const StyledTime = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const StyledMessageContainer = styled.div<PropsForAuthor>`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding: 10px;
  gap: 10px;
  border-radius: ${(props) =>
    props.isAuthor ? '15px 15px 0 15px' : '15px 15px 15px 0'};
`;

const StyledMessageTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const StyledMessageText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

function Message(props: Props) {
  const { message, user } = props;

  const author = () => {
    if (message.authorCompany) {
      return message.authorCompany.name;
    }
    return fullName(message.authorUser);
  };

  const avatarFiles = () => {
    if (message.authorCompany) {
      return message.authorCompany.avatarFilesIds?.split(',')[0] || '';
    }
    return message.authorUser.avatarFilesIds?.split(',')[0] || '';
  };

  const isAuthor = message.authorUserId === user.id;

  return (
    <StyledMessage isAuthor={isAuthor}>
      <StyledTime>{dateFormat(message.createdAt, 'hh:mm')}</StyledTime>
      <ProfileAvatar size={40} avatarFiles={avatarFiles()} />
      <StyledMessageContainer isAuthor={isAuthor}>
        <StyledMessageTitle>{author()}</StyledMessageTitle>
        <StyledMessageText>{message.message}</StyledMessageText>
      </StyledMessageContainer>
    </StyledMessage>
  );
}

export default memo(Message);
