import { Portfolio, User, UserStatus, UserStatusNames } from '@app/models';
import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import LoadableImage from '../LodableImage';
import Info from '../Info';
import { theme } from 'styled-tools';
import avatar from '../../assets/images/avatar.png';
import StarRating from 'components/StarRating/StarRating';
import { calculatePortfolios } from '@app/helpers';

interface CardProps extends Partial<User> {
  title?: string;
  description?: string;
  price?: string;
  onClick?: () => void;
  horizontal?: boolean;
  portfolios: Portfolio[];
  invertBgColorCard?: boolean;
}

const StyledUserCard = styled.div<{ invertBgColorCard: boolean }>`
  display: grid;
  grid-template-columns: 125px 1fr auto;
  /* grid-gap: 24px; */
  background-color: ${({ invertBgColorCard }) =>
    invertBgColorCard ? '#F5F5F5' : theme('color.white')};
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
`;

const StyledImage = styled.div<{ invertBgColorCard: boolean }>`
  width: 125px;
  height: 100%;
  background-color: ${({ invertBgColorCard }) =>
    invertBgColorCard ? '#F5F5F5' : theme('color.white')};
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 160px);
  grid-gap: 16px;
  padding: 0 0 0 24px;
  box-sizing: border-box;
`;

const StyledFooter = styled.div<{ isBusy: boolean }>`
  text-align: right;
  padding: 0 24px 0 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  > p {
    width: 74px;
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    margin: 0;
    background-color: ${({ isBusy }) =>
      isBusy ? theme('color.red') : theme('color.green')};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
    border-radius: 4px;
    color: ${theme('color.white')};
  }
`;

function UserCard(props: CardProps) {
  const {
    firstName = '',
    lastName = '',
    middleName = '',
    specialization = '',
    kato,
    imageIds,
    avatarFilesIds,
    status,
    rating,
    onClick,
    portfolios,
    invertBgColorCard = false,
  } = props;

  const onClickCard = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!onClick) {
        return;
      }

      onClick();
    },
    [onClick]
  );

  const fullName = useMemo(
    () => `${firstName} ${middleName} ${lastName}`,
    [firstName, lastName, middleName]
  );

  const portfolioDate = useMemo(() => {
    return calculatePortfolios(portfolios);
  }, [portfolios]);

  const images = useMemo(() => {
    if (imageIds) {
      return imageIds.split(',');
    }
    if (avatarFilesIds) {
      return avatarFilesIds.split(',');
    }
    return [];
  }, [avatarFilesIds, imageIds]);

  return (
    <StyledUserCard onClick={onClickCard} invertBgColorCard={invertBgColorCard}>
      <StyledImage invertBgColorCard={invertBgColorCard}>
        <LoadableImage imageIds={images} placeholder={avatar} />
      </StyledImage>
      <StyledContent>
        <Info label="Ф.И.О" value={fullName} small={true} />
        <Info
          label="Специализация"
          value={specialization || '-'}
          small={true}
        />
        <Info label="Опыт работы" value={portfolioDate} small={true} />
        <Info label="Регион" value={kato?.nameRu || '-'} small={true} />
        <Info
          label="Рейтинг"
          value={<StarRating rating={rating || 0} />}
          small={true}
        />
      </StyledContent>
      <StyledFooter isBusy={status === UserStatus.BUSY}>
        <p>{UserStatusNames[status || UserStatus.BUSY]}</p>
      </StyledFooter>
    </StyledUserCard>
  );
}

export default memo(UserCard);
