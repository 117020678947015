import { memo } from 'react';
import { moneyFormat } from '@app/helpers';
import styled from 'styled-components';
import { Measure, MeasureLabelsShort } from '@app/models';

interface IHorizontal {
  horizontal?: boolean;
}

const StyledPrice = styled.p<IHorizontal>`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #3a57e8;
  margin: 0;

  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.horizontal ? 1 : 1)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

interface Props {
  text: string;
  tenderType: number | undefined;
  horizontal?: boolean;
  measure?: Measure | null;
}

function CardPrice(props: Props) {
  const { text, tenderType, horizontal, measure } = props;

  const price = () => {
    if (!text) return ' ';
    let addText = '';
    if (tenderType === 20 && measure) {
      addText =
        tenderType === 20
          ? `/${MeasureLabelsShort[measure]}`
          : MeasureLabelsShort[Measure.KZT];
    }
    return `${moneyFormat(text)}${addText}`;
  };

  return (
    <StyledPrice className="smr-card__price" horizontal={horizontal}>
      {price()}
    </StyledPrice>
  );
}

export default memo(CardPrice);
