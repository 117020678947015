import instance from './instance';
import { PageableResponse } from './responses';
import { GovTender } from '@app/models';
import { PageableParams } from './params';

export function getGovTenders(
  params: Partial<GovTender> & PageableParams
): Promise<PageableResponse<GovTender>> {
  return instance()
    .get('/goszakup/tenders', {
      params,
    })
    .then((response) => response.data);
}
