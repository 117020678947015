import instance from './instance';
import { ChatMessage } from '../models';
import eventSourceInstance from './eventSourceInstance';

export interface CreateMessage extends Partial<ChatMessage> {}

export function createMessage(params: CreateMessage) {
  return instance(false)
    .post('/chat/message/create', params)
    .then((response) => response.data);
}
export function getChatEventSource(chatId: string): EventSource {
  return eventSourceInstance(true, `/chat/sse/${chatId}`);
}
