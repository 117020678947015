import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import './styles.scss';
import {
  createPortfolio,
  deleteCompanyPortfolioFile,
  deleteUserPortfolioFile,
  getCompanyPortfoliosFilesIds,
  getPortfolios,
  getUserPortfoliosFilesIds,
  updatePortfolio,
  uploadCompanyPortfolioFiles,
  uploadUserPortfolioFiles,
} from '@app/api';
import { Portfolio } from '@app/models';
import Loader from '../Loader';
import styled from 'styled-components';
import { StyledPaper } from '../Paper';
import Button from '../Button';
import { IconEdit16, IconPlusCircle24 } from '@app/icons';
import FilePicker from '../FilePicker';
import { ExpandablePaper } from '@app/common';
import { PortfolioForm } from '@app/forms';
import { useNotification, useUserContext } from '@app/providers';
import { calculatePortfolios, dateFormat } from '@app/helpers';

interface PortfolioListProps {
  companyId?: string;
  userId?: string;
  editable?: boolean;
}

const StyledPortfolioList = styled.div`
  background-color: #f5f5f5;
  margin: -24px -24px -48px;
  box-sizing: border-box;
`;

const StyledTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #0a0a0a;
  margin: 0;
`;

const StyledFileList = styled(StyledPaper)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const StyledForm = styled(StyledPaper)`
  margin: 16px 0;
  display: grid;
  grid-gap: 16px;
`;

const StyledFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLoaderContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledPortfolioWrapper = styled.div`
  + * {
    margin-top: 16px;
  }
`;

const StyledButtonControlContainer = styled.div`
  width: 100%;
  text-align: right;
`;

interface StyledPortfolioProps {
  portfolio: Portfolio;
  editable?: boolean;
  onEdit?: (portfolio: Portfolio) => void;
}

function StyledPortfolio(props: StyledPortfolioProps) {
  const { portfolio, editable, onEdit: onEditProps } = props;
  const { name, description, startDate, endDate } = portfolio;
  const [expanded, setExpanded] = useState<boolean>(false);

  const onChange = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  const onEdit = useCallback(() => {
    if (onEditProps) {
      onEditProps(portfolio);
    }
  }, [onEditProps, portfolio]);

  return (
    <StyledPortfolioWrapper>
      <ExpandablePaper
        title={name}
        onChange={onChange}
        expanded={expanded}
        description={`${dateFormat(startDate, 'dd.MM.yyyy')} - ${dateFormat(
          endDate,
          'dd.MM.yyyy'
        )}`}
      >
        {editable && (
          <StyledButtonControlContainer>
            <Button icon={IconEdit16} text="Редактировать" onClick={onEdit} />
          </StyledButtonControlContainer>
        )}
        {description}
      </ExpandablePaper>
    </StyledPortfolioWrapper>
  );
}

function PortfolioList(props: PortfolioListProps) {
  const { companyId, userId, editable = true } = props;
  const { showNotification } = useNotification();
  const { currentProfile } = useUserContext();
  const [pending, setPending] = useState<boolean>(true);
  const [updatingFiles, setUpdatingFiles] = useState<boolean>(false);
  const [updatingPortfolio, setUpdatingPortfolio] = useState<boolean>(false);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [editingPortfolio, setEditingPortfolio] = useState<Portfolio | null>(
    null
  );
  const [fileIds, setFileIds] = useState<string[]>([]);

  const onClickShowForm = useCallback(() => {
    setFormVisible(true);
  }, []);

  const onClickEdit = useCallback((portfolio: Portfolio) => {
    setFormVisible(true);
    setEditingPortfolio(portfolio);
  }, []);

  const onClickHiddenForm = useCallback(() => {
    setFormVisible(false);
  }, []);

  const onSubmitForm = useCallback(
    async (portfolio: Partial<Portfolio>) => {
      try {
        setUpdatingPortfolio(true);

        if (!!companyId) {
          portfolio.companyId = companyId;
        } else {
          portfolio.userId = userId;
        }

        if (editingPortfolio) {
          const response = await updatePortfolio(portfolio);
          setPortfolios((prevPortfolios) => {
            const index = prevPortfolios.findIndex(
              (item) => item.id === response.id
            );
            const data = [...prevPortfolios];
            if (index !== -1) data[index] = response;
            return data;
          });
        } else {
          const response = await createPortfolio(portfolio);
          setPortfolios((prevPortfolios) => [response, ...prevPortfolios]);
        }

        setFormVisible(false);
        setUpdatingPortfolio(false);

        showNotification({
          variant: 'success',
          message: 'Опыт работы был успешно добавлен',
        });
      } catch (e) {
        setUpdatingPortfolio(false);

        showNotification({
          variant: 'error',
          message: 'Не удалось добавить опыт работы',
        });
      }
    },
    [companyId, editingPortfolio, showNotification, userId]
  );

  const onChangeFiles = useCallback(
    async (files: File[]) => {
      try {
        let response;

        setUpdatingFiles(true);

        if (!!companyId) {
          response = await uploadCompanyPortfolioFiles(companyId, files);
        } else {
          response = await uploadUserPortfolioFiles(userId!, files);
        }

        setFileIds(response);
        setUpdatingFiles(false);
      } catch (e) {
        setUpdatingFiles(false);

        showNotification({
          variant: 'error',
          message: 'Не удалось загрузить файлы',
        });
      }
    },
    [companyId, showNotification, userId]
  );

  const getData = useCallback(async () => {
    try {
      let portfoliosFileIdsResponse;
      const params: Partial<Portfolio> = {};

      if (!!companyId) {
        params.companyId = companyId;
      } else {
        params.userId = userId;
      }

      const portfoliosResponse = await getPortfolios(params);

      if (!!companyId) {
        portfoliosFileIdsResponse = await getCompanyPortfoliosFilesIds(
          companyId
        );
      } else {
        portfoliosFileIdsResponse = await getUserPortfoliosFilesIds(userId!);
      }

      setPortfolios(portfoliosResponse);
      setFileIds(portfoliosFileIdsResponse);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [companyId, userId]);

  const onDeleteFile = useCallback(
    async (fileId: string) => {
      try {
        setFileIds((prevFileIds) =>
          prevFileIds.filter((item) => item !== fileId)
        );

        if (!!companyId) {
          await deleteCompanyPortfolioFile(companyId, fileId);
        } else {
          await deleteUserPortfolioFile(userId!, fileId);
        }

        return true;
      } catch (e) {
        return false;
      }
    },
    [companyId, userId]
  );

  const portfolioDate = useMemo(() => {
    return calculatePortfolios(portfolios);
  }, [portfolios]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  return (
    <StyledPortfolioList>
      <StyledFileList>
        <StyledTitle>Портфолио</StyledTitle>
        <FilePicker
          loadableFiles={fileIds}
          onChange={onChangeFiles}
          showLoader={updatingFiles}
          editable={editable}
          onDelete={onDeleteFile}
        />
      </StyledFileList>
      <StyledForm>
        <StyledFormHeader>
          <StyledTitle>
            Опыт работы
            {portfolios.length > 0 && currentProfile.currentType === 'user'
              ? `: ${portfolioDate}`
              : ''}
          </StyledTitle>
          {editable && (
            <Button
              icon={IconPlusCircle24}
              text={
                editingPortfolio
                  ? 'Обновить опыт работы'
                  : 'Добавить опыт работы'
              }
              onClick={onClickShowForm}
            />
          )}
        </StyledFormHeader>
        {formVisible && (
          <PortfolioForm
            onSubmit={onSubmitForm}
            onCancel={onClickHiddenForm}
            pending={updatingPortfolio}
            portfolio={editingPortfolio}
          />
        )}
      </StyledForm>
      {portfolios
        .filter((portfolio) => !portfolio.filesIds)
        .map((portfolio) => (
          <StyledPortfolio
            key={portfolio.id}
            portfolio={portfolio}
            editable={editable}
            onEdit={onClickEdit}
          />
        ))}
    </StyledPortfolioList>
  );
}

export default memo(PortfolioList);
