import React, { memo, useCallback, useEffect, useState } from 'react';
import { ProfileUser, WelcomeBanner } from '@app/common';
import { Loader, StyledContainer } from '@app/components';
import { getUserById } from '@app/api';
import { User } from '@app/models';
import styled from 'styled-components';
import { LayoutDefault } from '@app/layouts';
import { useParams } from 'react-router-dom';

const StyledLoader = styled.div`
  text-align: center;
  margin: 16px 0;
`;

const Grid = styled(StyledContainer)`
  display: grid;
  grid-gap: 16px;
`;

function PageUser() {
  const { id } = useParams<'id'>();
  const [pending, setPending] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const getData = useCallback(async () => {
    try {
      if (!id) return;

      setPending(true);

      const response = await getUserById(id);

      setUser(response);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return <Loader />;
  }

  if (!user) {
    return null;
  }

  return (
    <LayoutDefault>
      <WelcomeBanner />
      {pending && (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      )}
      <Grid>
        <ProfileUser user={user} />
      </Grid>
    </LayoutDefault>
  );
}

export default memo(PageUser);
