import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DocsSearch, PartnerInfo, RepliesSearch, Toolbar } from '@app/common';
import { Button, Grid, Loader, Tabs } from '@app/components';
import { useNavigate, useParams } from 'react-router-dom';
import { Doc, DocTypes, Partner, Reply, UserType } from '@app/models';
import {
  getAllContracts,
  getCompanyById,
  getContracts,
  getUserById,
  PageableParams,
} from '@app/api';
import { fullName } from '@app/helpers';
import { getReplies } from '@app/api';
import { useUserContext } from '@app/providers';
import { ModalAddPartner } from '@app/modals';
import { EventEmitter } from '../../../../EventEmitter';

const PageProfilePartner = () => {
  const { user, company } = useUserContext();
  const { userType, id } = useParams();
  const navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(true);
  const [partner, setPartner] = useState<Partner | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onClickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onClickContract = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setModalVisible(true);
    },
    []
  );

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onAddPartner = useCallback(() => {
    setModalVisible(false);

    EventEmitter.emit('updateContractsSearch');
  }, []);

  const getData = useCallback(async () => {
    try {
      setPending(true);

      const defaultDocParams: Partial<Doc> = {
        type: DocTypes.CONTRACT,
      };

      if (user.type === UserType.ENTITY) {
        defaultDocParams.companyId = company!.id;
      } else {
        defaultDocParams.ownerId = user.id;
      }

      if (Number(userType) === UserType.ENTITY) {
        const companyResponse = await getCompanyById(id!);

        setPartner({
          id: companyResponse.id,
          iin: null,
          docsCount: (
            await getAllContracts({
              ...defaultDocParams,
              recipientCompanyId: companyResponse.id,
            })
          ).length,
          bin: companyResponse.bin,
          okeds: companyResponse.okeds,
          kato: companyResponse.kato,
          name: companyResponse.name,
        });
      } else {
        const userResponse = await getUserById(id!);

        setPartner({
          id: userResponse.id,
          iin: userResponse.iin,
          docsCount: (
            await getAllContracts({
              ...defaultDocParams,
              recipientId: userResponse.id,
            })
          ).length,
          bin: null,
          okeds: userResponse.okeds,
          kato: userResponse.kato,
          name: fullName(userResponse),
        });
      }

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [company, user, id, userType]);

  const getRepliesData = useCallback(
    (params: PageableParams) => {
      const defaultParams: Partial<Reply> = {};

      if (Number(userType) === UserType.ENTITY) {
        defaultParams.companyId = partner!.id;
      } else {
        defaultParams.ownerId = partner!.id;
      }

      return getReplies({
        ...params,
        ...defaultParams,
      });
    },
    [partner, userType]
  );

  const loadContracts = useCallback(
    (params: PageableParams) => {
      const defaultParams: Partial<Doc> = {};

      if (user.type === UserType.ENTITY) {
        defaultParams.companyId = company!.id;
      } else {
        defaultParams.ownerId = user.id;
      }

      if (!!partner!.bin) {
        defaultParams.recipientCompanyId = partner!.id;
      } else {
        defaultParams.recipientId = partner!.id;
      }

      return getContracts({
        ...params,
        ...defaultParams,
        type: DocTypes.CONTRACT,
      });
    },
    [partner, company, user]
  );

  const tabLabels = useMemo(() => {
    return ['Договоры', 'Отклики'];
  }, []);

  const controls = useMemo(() => {
    return [<Button text="Добавить договор" onClick={onClickContract} />];
  }, [onClickContract]);

  useEffect(() => {
    getData();
  }, []);

  if (pending) {
    return <Loader />;
  }

  if (!partner) {
    return null;
  }

  return (
    <Grid>
      <Toolbar onClickBack={onClickBack} title={partner.name} />
      <PartnerInfo partner={partner}>
        <Tabs labels={tabLabels} controls={controls}>
          <DocsSearch
            getData={loadContracts}
            refreshEventName="updateContractsSearch"
          />
          <RepliesSearch getData={getRepliesData} />
        </Tabs>
      </PartnerInfo>
      <ModalAddPartner
        visible={modalVisible}
        onClose={onCloseModal}
        onSuccess={onAddPartner}
        recipientUser={!!partner.iin ? partner : null}
        recipientCompany={!!partner.bin ? partner : null}
        userType={user.type}
        companyId={user.type === UserType.ENTITY ? company!.id : null}
      />
    </Grid>
  );
};

export default memo(PageProfilePartner);
