import React, { memo, useMemo } from 'react';
import { Info, StyledOkedListBlock } from '@app/components';
import { Company } from '@app/models';
import { useTranslation } from 'react-i18next';

interface Props {
  company: Company;
}

function ProfileCompanyAdditionalInfo(props: Props) {
  const { company } = props;
  const { t } = useTranslation();
  const okedList = useMemo(() => {
    if (company.okeds.length < 1) {
      return t('notSpecified');
    }
    return <StyledOkedListBlock list={company.okeds} />;
  }, [t, company.okeds]);
  return (
    <>
      <Info label="ОКЭД" value={okedList} />
      <Info
        label="Дополнительная информация"
        value={company.additionalInformation || t('notSpecified')}
      />
    </>
  );
}

export default memo(ProfileCompanyAdditionalInfo);
