import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@app/components';

interface Props {
  className?: string;
  size?: number;
  borderRadius?: number;
  avatarFiles?: string;
  editable?: boolean;
  onSubmit?: (file: File) => void;
  customPlaceholder?: string;
}

const StyledWrapper = styled.div``;

function ProfileAvatar(props: Props) {
  const {
    onSubmit,
    avatarFiles,
    size = 244,
    borderRadius = 50,
    editable = false,
    customPlaceholder,
    className = '',
  } = props;
  const [file, setFile] = useState<File | null>(null);

  const onChangeAvatar = useCallback(
    (value: File | null) => {
      if (value) {
        setFile(value);
        onSubmit && onSubmit(value);
      }
    },
    [onSubmit]
  );

  return (
    <StyledWrapper className={`${className}`.trim()}>
      <Avatar
        size={size}
        src={avatarFiles}
        customPlaceholder={customPlaceholder}
        value={file}
        onChange={onChangeAvatar}
        editable={editable}
        borderRadius={borderRadius}
      />
    </StyledWrapper>
  );
}

export default memo(ProfileAvatar);
