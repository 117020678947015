import { Reply, Tender } from '../models';
import instance from './instance';
import { PageableParams } from './params';
import { PageableResponse } from './responses';

export interface CreateTenderData
  extends Pick<
    Tender,
    | 'name'
    | 'description'
    | 'price'
    | 'katoCode'
    | 'deliveryDate'
    | 'endDate'
    | 'type'
    | 'category'
    | 'measure'
  > {
  files: File[];
  okedCode: string | null;
  requirements?: string;
  companyId: string | null;
}

export interface TenderReplyData {
  adsId: string;
  replyId: string;
}

export function createTender(data: CreateTenderData): Promise<Tender> {
  const formData = new FormData();

  Object.keys(data).forEach((dataKey) => {
    if (dataKey === 'files') {
      data[dataKey].forEach((file) => {
        formData.append(dataKey, file as Blob);
      });
    } else {
      if (data[dataKey as keyof CreateTenderData] !== null) {
        formData.append(
          dataKey,
          data[dataKey as keyof CreateTenderData] as string | Blob
        );
      }
    }
  });

  return instance()
    .post('/tenders/create', formData)
    .then((response) => response.data);
}

export function getTenders(
  params: PageableParams & Partial<Tender>
): Promise<PageableResponse<Tender>> {
  return instance()
    .get('/tenders/getAll', {
      params,
    })
    .then((response) => response.data);
}

export function getMyTenders(
  params: PageableParams
): Promise<PageableResponse<Tender>> {
  return instance()
    .get('/tenders/getMy', {
      params,
    })
    .then((response) => response.data);
}

export function getTenderById(id: string): Promise<Tender> {
  return instance()
    .get(`/tenders/getById/${id}`)
    .then((response) => response.data);
}

export function getReplyById(replyId: string): Promise<Reply> {
  return instance()
    .get(`/replies/getById/${replyId}`)
    .then((response) => response.data);
}

export function activateTender(tenderId: string): Promise<Tender> {
  return instance()
    .put(`/tenders/activate/${tenderId}`)
    .then((response) => response.data);
}

export function hideTender(tenderId: string): Promise<Tender> {
  return instance()
    .put(`/tenders/hide/${tenderId}`)
    .then((response) => response.data);
}

export function deleteTender(tenderId: string): Promise<Tender> {
  return instance()
    .put(`/tenders/delete/${tenderId}`)
    .then((response) => response.data);
}

export function updateTender(
  id: string,
  data: Partial<CreateTenderData>
): Promise<Tender> {
  return instance()
    .patch(`/tenders/update/${id}`, data)
    .then((response) => response.data);
}

export function uploadTenderFiles(
  id: string,
  files: File[],
  fieldName: 'filesIds' | 'imagesIds'
): Promise<Tender> {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file as Blob);
  });

  return instance()
    .post(`/tenders/${id}/addFiles/${fieldName}`, formData)
    .then((response) => response.data);
}

export function deleteTenderFile(id: string, fileId: string): Promise<boolean> {
  return instance()
    .delete(`/tenders/${id}/files/${fileId}`)
    .then((response) => response.data);
}
