import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LayoutDefault } from '@app/layouts';
import { Button, Loader, StyledContainer } from '@app/components';
import { Vehicle } from '@app/models';
import { useParams } from 'react-router-dom';
import { getVehicleById } from '@app/api';
import { AuthorSection, VehicleInfo, WelcomeBanner } from '@app/common';
import styled from 'styled-components';
import { useNotification, useUserContext } from '@app/providers';

interface Props {}

const Grid = styled(StyledContainer)`
  display: grid;
  grid-gap: 16px;
`;

const StyledLoader = styled.div`
  text-align: center;
  margin: 16px 0;
`;

function VehiclePage(props: Props) {
  const { id } = useParams<'id'>();
  const { showNotification } = useNotification();
  const { user, showSignInModal, currentProfile } = useUserContext();
  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      const vehicleResponse = await getVehicleById(id as string);

      setVehicle(vehicleResponse);
      setError(false);
      setPending(false);
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [id]);

  const findCompany = vehicle?.owner?.companies?.find(
    (val) => val.id === vehicle?.companyId
  );

  const onClickShare = useCallback(async () => {
    try {
      if (!user.authenticated) {
        showSignInModal();

        return;
      }
      const url = `${window.location.hostname}/vehicles/${vehicle?.id}`;
      await navigator.clipboard.writeText(url);

      showNotification({
        variant: 'success',
        message: 'Ссылка скопирована в буфер обмена',
      });
    } catch (e) {
      showNotification({
        variant: 'error',
        message: 'Ссылка не была скопирована',
      });
    }
  }, [user.authenticated, vehicle?.id, showNotification, showSignInModal]);

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!vehicle) {
      return [];
    }

    const returnActionButtons = [
      <Button
        text="Поделиться"
        disabled={currentProfile.blocked}
        onClick={onClickShare}
      />,
    ];

    return [...returnActionButtons];
  }, [currentProfile.blocked, onClickShare, vehicle]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <LayoutDefault>
      <WelcomeBanner />
      {pending && (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      )}
      {!!vehicle && (
        <Grid>
          <VehicleInfo vehicle={vehicle} actions={actionButtons} />
          <AuthorSection author={vehicle.owner} company={findCompany} />
        </Grid>
      )}
    </LayoutDefault>
  );
}

export default memo(VehiclePage);
