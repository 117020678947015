import { memo, useCallback, useState } from 'react';
import { Modal, ModalProps } from '../../components';

import { useNotificationFromDBContext, useUserContext } from '@app/providers';
import {
  getNotificationsForUser,
  getUserById,
  signIn,
  SignInData,
} from '@app/api';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { parseJwt } from '@app/helpers';

import './styles.scss';
import { RestorePasswordForm, SignInForm } from '@app/forms';

interface SignInModalProps extends ModalProps {}

function SignInModal(props: SignInModalProps) {
  const { visible, className = '' } = props;

  const navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { setUser, hideSignInModal } = useUserContext();
  const { setNotifications } = useNotificationFromDBContext();

  const onClickSignUp = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (pending) {
        return;
      }

      hideSignInModal();

      navigate('/sign-up');
    },
    [navigate, pending, hideSignInModal]
  );

  const onSubmit = useCallback(
    (data: SignInData): Promise<boolean> => {
      return new Promise(async (resolve, reject) => {
        try {
          if (pending) {
            return;
          }

          setPending(true);

          const signInResponse = await signIn(data);

          setTimeout(async () => {
            setPending(false);

            Cookies.set('accessToken', signInResponse.accessToken);

            const userResponse = await getUserById(
              parseJwt(signInResponse.accessToken).id
            );

            setUser({
              authenticated: true,
              ...userResponse,
            });

            hideSignInModal();

            const notificationsResponse = await getNotificationsForUser({
              isRead: false,
            });

            setNotifications(notificationsResponse);

            resolve(true);

            navigate('/');
            // window.location.href = '/';
          }, 800);
        } catch (e) {
          setTimeout(() => {
            setPending(false);

            reject(e);
          }, 800);
        }
      });
    },
    [hideSignInModal, navigate, pending, setNotifications, setUser]
  );

  const onCurrentStepHandler = useCallback(
    (step: number) => {
      setCurrentStep(step);
    },
    [setCurrentStep]
  );

  if (!visible) {
    return null;
  }

  return (
    <Modal
      className={`m-sign-in ${className}`.trim()}
      visible={visible}
      onClose={hideSignInModal}
      size="signin"
    >
      {currentStep === 2 ? (
        <RestorePasswordForm
          onSubmit={onSubmit}
          pending={pending}
          onCurrentStepHandler={onCurrentStepHandler}
          currentStep={currentStep}
        />
      ) : (
        <SignInForm
          onSubmit={onSubmit}
          pending={pending}
          onClickSignUp={onClickSignUp}
          onCurrentStepHandler={onCurrentStepHandler}
          currentStep={currentStep}
          onCancel={hideSignInModal}
        />
      )}
    </Modal>
  );
}

export default memo(SignInModal);
