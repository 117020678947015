import React, { Component } from 'react';
import { Stepper, StyledContainer } from '@app/components';
import { DN } from '../../libs';
import { UserType } from '@app/models';
import { LayoutDefault } from '@app/layouts';
import {
  CompanyForm,
  UserForm,
  UserFormData,
  UserTypeForm,
} from './components';
import './styles.scss';
import styled from 'styled-components';
import { withRouter, WithRouterProps } from '../../hoc';

interface Props extends WithRouterProps {}

interface State {
  currentStep: number;
  maxCurrentStep: number;
  dn: DN | null;
  user: UserFormData;
  userType: UserType;
}

const StyledSignUpContainer = styled(StyledContainer)`
  padding-top: 16px;
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;

class SignUpPage extends Component<Props, State> {
  state: State = {
    currentStep: 0,
    maxCurrentStep: 0,
    dn: null,
    user: {} as UserFormData,
    userType: UserType.INDIVIDUAL,
  };

  onSubmitUserTypeForm = (type: UserType, dnData: DN | null) => {
    this.setState(({ maxCurrentStep }) => ({
      userType: type,
      dn: dnData || null,
      currentStep: 1,
      maxCurrentStep: maxCurrentStep <= 1 ? 1 : maxCurrentStep,
    }));
  };

  onSubmitUserForm = (user: UserFormData) => {
    this.setState(({ maxCurrentStep }) => ({
      user,
      currentStep: 2,
      maxCurrentStep: maxCurrentStep <= 2 ? 2 : maxCurrentStep,
    }));
  };

  onClose = () => {
    this.props.router!.navigate(-1);
  };

  render() {
    const { currentStep, maxCurrentStep, dn, userType, user } = this.state;

    const steps = [
      {
        onClick: () => {
          this.setState(({ currentStep, maxCurrentStep }) => ({
            currentStep: maxCurrentStep >= 0 ? 0 : currentStep,
          }));
        },
        title: 'Выберите тип пользования',
      },
      {
        onClick: () => {
          this.setState(({ currentStep, maxCurrentStep }) => ({
            currentStep: maxCurrentStep >= 1 ? 1 : currentStep,
          }));
        },
        title: 'Заполните личные данные',
      },
    ];

    if (userType === UserType.ENTITY) {
      steps.push({
        onClick: () => {
          this.setState(({ currentStep, maxCurrentStep }) => ({
            currentStep: maxCurrentStep >= 2 ? 2 : currentStep,
          }));
        },
        title: 'Организация',
      });
    }

    return (
      <LayoutDefault className="p-sign-up">
        <Stepper
          title="Регистрация"
          steps={steps}
          currentStep={currentStep}
          maxCurrentStep={maxCurrentStep}
          onClose={this.onClose}
        />
        <StyledSignUpContainer>
          {currentStep >= 0 && (
            <UserTypeForm
              defaultExpanded={currentStep === 0}
              onSubmit={this.onSubmitUserTypeForm}
            />
          )}
          {currentStep >= 1 && (
            <UserForm
              userType={userType}
              dn={dn}
              defaultExpanded={currentStep === 1}
              onSubmit={this.onSubmitUserForm}
            />
          )}
          {currentStep >= 2 && <CompanyForm dn={dn as DN} user={user} />}
        </StyledSignUpContainer>
      </LayoutDefault>
    );
  }
}

export default withRouter<Props>(SignUpPage);
