import React, { useCallback, useState } from 'react';
import {
  Button,
  DatePicker,
  KatoSelectTrigger,
  SelectOption,
  TextField,
} from '@app/components';
import { IconSearch } from '@app/icons';
import styles from './styles.module.scss';
import { RenderFilterProps } from '@app/templates';
import * as yup from 'yup';
import { useForm } from '@app/forms';
import { Doc, Kato } from '@app/models';

export interface CompaniesFilterData extends Partial<Doc> {
  katoCode?: string;
  companyBin?: string;
}

interface Props extends RenderFilterProps<CompaniesFilterData> {}

interface PortfolioData {
  name: string;
  bin: string;
  // description: string;
  // position: string;
  kato: SelectOption<Kato> | null;
  startDate: Date | null;
  endDate: Date | null;
}

const schema = yup.object().shape({
  // naming: yup.string().required(),
  // startDate: yup.date().required(),
  // endDate: yup.date().required(),
  // bin: yup.string().required(),
  // position: yup.string().required(),
});

function CompaniesFilter(props: Props) {
  const { onSubmit, pending } = props;
  const [tenderNumber, setTenderNumber] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const { values, onChange, errors, validate } = useForm<PortfolioData>({
    name: '',
    // description: '',
    // position: '',
    bin: '',
    kato: null,
    startDate: null,
    endDate: null,
  });

  const onSubmitForm = useCallback(async () => {
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    const params: CompaniesFilterData = {};

    if (!!values.name) {
      params.name = values.name;
    }
    if (!!values.bin) {
      params.companyBin = values.bin;
    }
    if (!!values.startDate) {
      params.createdAt = values.startDate.toJSON();
    }
    if (!!values.endDate) {
      params.endDate = values.endDate.toJSON();
    }
    if (!!values.kato) {
      params.katoCode = values.kato.value.code;
    }

    onSubmit(params);
  }, [validate, values, onSubmit]);

  const onChangeTenderNumber = useCallback(
    (value: string) => setTenderNumber(value),
    []
  );

  const onChangeCompanyName = useCallback(
    (value: string) => setCompanyName(value),
    []
  );

  return (
    <div className={styles.companies}>
      <div className={styles.inputContainer}>
        <div className={styles.textField}>
          <TextField
            label="наименование партнера"
            placeholder="Введите наименование"
            value={values.name}
            onChange={onChange}
            name={'name'}
          />
        </div>
        <div className={styles.selectContainer}>
          <div>
            {/* <Select
              options={[]}
              label="дата начала сотрудничества"
              value={null}
              placeholder="Выберите дату"
              onChange={() => {}}
            /> */}
            <DatePicker
              value={values.startDate}
              name="startDate"
              onChange={onChange}
              label={'дата начала сотрудничества'}
            />
          </div>
          <div>
            {/* <Select
              options={[]}
              label="дата окончания сотрудничества"
              value={null}
              placeholder="Выберите дату"
              onChange={() => {}}
            /> */}
            <DatePicker
              value={values.endDate}
              name="endDate"
              onChange={onChange}
              label={'дата окончания сотрудничества'}
            />
          </div>
          <div>
            <TextField
              label="бин"
              placeholder="Выберите БИН"
              value={values.bin}
              onChange={onChange}
              name={'bin'}
            />
          </div>
          <div>
            <KatoSelectTrigger
              label="регион"
              value={!!values.kato ? values.kato.value.nameRu : ''}
              placeholder="Выберите регион"
              name="kato"
              onChange={onChange}
            />
          </div>
        </div>

        {/* <div>
          <TextField
            label="автор объявления"
            placeholder="Пользователь"
            value={companyName}
            onChange={onChangeCompanyName}
          />
        </div> */}
      </div>
      <div className="companies-filter__footer">
        <Button
          loading={pending}
          onClick={onSubmitForm}
          text="Найти"
          icon={IconSearch}
        />
      </div>
    </div>
  );
}

export default CompaniesFilter;
