import { WithCreatedUpdated } from './Default';
import { Oked } from './Oked';
import { User } from './User';
import { Kato } from './Kato';

export enum CompanyTypeNames {
  TOO = 'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ',
  TDO = 'ТОВАРИЩЕСТВА С ДОПОЛНИТЕЛЬНОЙ ОТВЕТСТВЕННОСТЬЮ',
  PT = 'ПОЛНОЕ ТОВАРИЩЕСТВО',
  KT = 'КОММАНДИТНОЕ ТОВАРИЩЕСТВО',
  PK = 'ПРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ',
  AO = 'АКЦИОНЕРНОЕ ОБЩЕСТВО',
}

export enum CompanyTypes {
  TOO = 10,
  TDO = 20,
  PT = 30,
  KT = 40,
  PK = 50,
  AO = 60,
}

export interface Company extends WithCreatedUpdated {
  id: string;
  bin: string;
  okedCode: string | null;
  katoCode: string | null;
  name: string;
  type: CompanyTypes;
  okeds: Oked[];
  owner: User;
  kato: Kato;
  email: string | null;
  instagram: string | null;
  telegram: string | null;
  phones: string | null;
  legalAddress: string | null;
  address: string | null;
  webSite: string | null;
  additionalInformation: string | null;
  whatsAppNumber: string | null;
  filesIds: string | null;
  avatarFilesIds: string | null;
  rating: number;
  employees: User;
  blocked: boolean;
}
