import React, { Component, memo } from 'react';
import { Modal, ModalProps, Stepper } from '@app/components';
import {
  FilePickerForm,
  TenderCategoryForm,
  TenderForm,
  TenderFormData,
  TenderTypeForm,
  VehicleCreateForm,
} from '@app/forms';
import { convertToDate, setMask } from '@app/helpers';
import { TenderCategory, TenderTypes } from '@app/models';
import { createTender, createVehicle } from '@app/api';
import { VehicleFormData } from '../../forms/VehicleCraete/VehicleCreate';
import './styles.scss';
import { EventEmitter } from '../../EventEmitter';

interface Props extends ModalProps {
  companyId: string | null;
  closeModal: () => void;
  tenderCategory?: TenderCategory;
  data?: Partial<TenderFormData>;
}

interface State {
  currentStep: number;
  maxCurrentStep: number;
  files: File[];
  data: TenderFormData;
  pending: boolean;
  tenderCategory: TenderCategory | null;
  tenderType: TenderTypes | null;
  vehicle: VehicleFormData | null;
}

class ModalTenderCreate extends Component<Props, State> {
  steps = [
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 0 ? 0 : currentStep,
        }));
      },
      title: 'Выберите предмет объявления',
    },
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 1 ? 1 : currentStep,
        }));
      },
      title: 'Выберите тип объявления',
    },
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 2 ? 2 : currentStep,
        }));
      },
      title: 'Заполните данные',
    },
    {
      onClick: () => {
        this.setState(({ currentStep, maxCurrentStep }) => ({
          currentStep: maxCurrentStep >= 3 ? 3 : currentStep,
        }));
      },
      title: 'Загрузите документы и файлы',
    },
  ];

  getInitialState = (): State => {
    const { tenderCategory } = this.props;

    return {
      currentStep: tenderCategory
        ? tenderCategory === TenderCategory.VEHICLE
          ? 1
          : 2
        : 0,
      maxCurrentStep: tenderCategory
        ? tenderCategory === TenderCategory.VEHICLE
          ? 1
          : 2
        : 0,
      files: [],
      pending: false,
      data: {} as TenderFormData,
      tenderCategory: tenderCategory || null,
      tenderType:
        tenderCategory === TenderCategory.VEHICLE ? null : TenderTypes.REQUEST,
      vehicle: null,
    };
  };

  state: State = this.getInitialState();

  componentWillReceiveProps(nextProps: Props) {
    const { tenderCategory, visible } = nextProps;

    if (tenderCategory !== this.props.tenderCategory) {
      this.setState({
        currentStep: tenderCategory
          ? tenderCategory === TenderCategory.VEHICLE
            ? 1
            : 2
          : 0,
        maxCurrentStep: tenderCategory
          ? tenderCategory === TenderCategory.VEHICLE
            ? 1
            : 2
          : 0,
        tenderCategory: tenderCategory || null,
        tenderType:
          tenderCategory === TenderCategory.VEHICLE
            ? null
            : TenderTypes.REQUEST,
      });
    }

    if (!visible) {
      this.setState(this.getInitialState());
    }
  }

  createTender = () => {
    const { data, files, tenderType, tenderCategory, vehicle } = this.state;

    if (tenderType === TenderTypes.REQUEST) {
      createTender({
        okedCode: data.oked,
        katoCode: data.kato,
        name: data.name,
        description: data.description,
        price: data.price,
        deliveryDate: data.deliveryDate!.toString(),
        endDate: data.endDate!.toString(),
        files,
        type: TenderTypes.REQUEST,
        category: tenderCategory!,
        requirements: data.requirements!.join(','),
        companyId: this.props.companyId,
        measure: data.measure,
      })
        .then(() => {
          this.setState({
            currentStep: 0,
            maxCurrentStep: 0,
            files: [],
            pending: false,
            data: {} as TenderFormData,
            tenderCategory: null,
            tenderType: null,
          });

          EventEmitter.emit('updateHomeSearch');

          this.props.closeModal();
        })
        .catch(() => {
          this.setState({
            pending: false,
          });
        });

      return;
    }

    if (tenderCategory === TenderCategory.VEHICLE) {
      createVehicle({
        name: vehicle!.name,
        description: vehicle!.description,
        price: vehicle!.price,
        files,
        brand: vehicle!.brand,
        type: vehicle!.type,
        companyId: this.props.companyId,
        dateOfIssue: convertToDate(
          setMask(vehicle!.dateOfIssue, '##.##.####')
        ).toJSON(),
        katoCode: vehicle!.kato,
        measure: vehicle?.measure ? vehicle.measure.value : null,
      })
        .then(() => {
          this.setState({
            currentStep: 0,
            maxCurrentStep: 0,
            files: [],
            pending: false,
            data: {} as TenderFormData,
            tenderCategory: null,
            tenderType: null,
          });

          EventEmitter.emit('updateHomeSearch');

          this.props.closeModal();
        })
        .catch(() => {
          this.setState({
            pending: false,
          });
        });

      return;
    }
  };

  onSubmitTenderCategoryForm = (
    tenderCategory: TenderCategory,
    isSubmit: boolean
  ) => {
    this.setState((prevState) => {
      const settableStep = isSubmit
        ? tenderCategory === TenderCategory.VEHICLE
          ? 1
          : 2
        : prevState.currentStep;

      return {
        tenderCategory,
        currentStep: settableStep,
        maxCurrentStep:
          settableStep >= prevState.maxCurrentStep
            ? settableStep
            : prevState.maxCurrentStep,
        tenderType:
          tenderCategory === TenderCategory.VEHICLE
            ? null
            : TenderTypes.REQUEST,
      };
    });
  };

  onSubmitTenderTypeForm = (tenderType: TenderTypes, isSubmit: boolean) => {
    this.setState((prevState) => {
      const settableStep = isSubmit ? 2 : prevState.currentStep;

      return {
        tenderType,
        currentStep: settableStep,
        maxCurrentStep:
          settableStep >= prevState.maxCurrentStep
            ? settableStep
            : prevState.maxCurrentStep,
      };
    });
  };

  onSubmitTenderDataForm = (data: TenderFormData) => {
    this.setState((prevState) => ({
      currentStep: 3,
      data,
      maxCurrentStep:
        prevState.maxCurrentStep < 3 ? 3 : prevState.maxCurrentStep,
    }));
  };

  onSubmitVehicleDataForm = (vehicle: VehicleFormData) => {
    this.setState((prevState) => ({
      currentStep: 3,
      vehicle,
      maxCurrentStep:
        prevState.maxCurrentStep < 3 ? 3 : prevState.maxCurrentStep,
    }));
  };

  onSubmitFiles = (files: File[]) => {
    this.setState(
      {
        files,
        pending: true,
      },
      () => {
        this.createTender();
      }
    );
  };

  render() {
    const { visible, closeModal } = this.props;
    const { currentStep, maxCurrentStep, tenderCategory, tenderType, pending } =
      this.state;

    if (!visible) {
      return null;
    }

    return (
      <Modal
        visible={visible}
        onClose={closeModal}
        className="modal-tender-create"
        size="large"
      >
        <Stepper
          onClose={closeModal}
          title="Создать объявление"
          steps={this.steps}
          currentStep={currentStep}
          maxCurrentStep={maxCurrentStep}
        />
        <div className="modal-tender-create__content">
          {currentStep >= 0 && (
            <TenderCategoryForm
              defaultExpanded={currentStep === 0}
              value={tenderCategory}
              onSubmit={this.onSubmitTenderCategoryForm}
            />
          )}
          {currentStep >= 1 && (
            <TenderTypeForm
              disableSwitcher={tenderCategory !== TenderCategory.VEHICLE}
              value={tenderType}
              defaultExpanded={currentStep === 1}
              onSubmit={this.onSubmitTenderTypeForm}
            />
          )}
          {currentStep >= 2 && tenderType === TenderTypes.REQUEST && (
            <TenderForm
              data={this.props.data}
              tenderCategory={tenderCategory}
              defaultExpanded={currentStep === 2}
              onSubmit={this.onSubmitTenderDataForm}
            />
          )}
          {currentStep >= 2 && tenderType === TenderTypes.OFFER && (
            <VehicleCreateForm
              defaultExpanded={currentStep === 2}
              onSubmit={this.onSubmitVehicleDataForm}
            />
          )}
          {currentStep >= 3 && (
            <FilePickerForm
              pending={pending}
              required={tenderType === TenderTypes.OFFER}
              defaultExpanded={currentStep === 3}
              onSubmit={this.onSubmitFiles}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default memo(ModalTenderCreate);
