import React, { useCallback, useState } from 'react';
import { SearchTemplate } from '@app/templates';
import { getGovTenders, PageableParams } from '@app/api';
import { GovTender, Measure, MeasureLabelsShort, UserType } from '@app/models';
import { useUserContext } from '@app/providers';
import { Button, Info } from '@app/components';
import styled from 'styled-components';
import { moneyFormat } from '@app/helpers';
import { format } from 'date-fns';
import { ModalTenderCreate } from '@app/modals';

const StyledCompany = styled.div`
  padding: 16px;
  box-sizing: border-box;
  gap: 24px;
  background: #f5f5f5;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 300px auto 150px 150px 120px 120px;
  align-items: center;
`;

function GovTendersSearch() {
  const [govTender, setGovTender] = useState<GovTender | null>(null);
  const { user, company } = useUserContext();

  const onCloseModal = useCallback(() => {
    setGovTender(null);
  }, []);

  const onClickPublish = useCallback(
    (item: GovTender) => () => {
      setGovTender(item);
    },
    []
  );

  const renderFilter = useCallback(() => {
    return null;
  }, []);

  const renderItem = useCallback(
    (item: GovTender) => {
      return (
        <StyledCompany>
          <Info label="Наименование" value={item.anno.name_ru} small={true} />
          <Info label="Заказчик" value={item.anno.org_name_ru} small={true} />
          <Info
            label="Сумма"
            value={`${moneyFormat(item.contract_sum)} ${
              MeasureLabelsShort[Measure.KZT]
            }`}
            small={true}
          />
          <Info
            label="Сумма с НДС"
            value={`${moneyFormat(item.contract_sum_wnds)} ${
              MeasureLabelsShort[Measure.KZT]
            }`}
            small={true}
          />
          <Info
            label="Дата заключения"
            value={format(new Date(item.crdate), 'dd.MM.yyyy')}
            small={true}
          />
          <Button
            text="Опубликовать"
            onClick={onClickPublish(item)}
            disabled={!!govTender}
            loading={!!govTender && govTender.id === item.id}
          />
        </StyledCompany>
      );
    },
    [govTender, onClickPublish]
  );

  const getData = useCallback(
    (params: PageableParams) => {
      const defaultParams: Partial<GovTender> = {};

      if (user.type === UserType.INDIVIDUAL) {
        defaultParams.supplier_biin = user.iin!;
      } else {
        defaultParams.supplier_biin = company!.bin!;
      }

      return getGovTenders({
        ...params,
        ...defaultParams,
      });
    },
    [company, user]
  );

  return (
    <>
      <SearchTemplate<GovTender>
        columns={1}
        renderItem={renderItem}
        getData={getData}
        renderFilter={renderFilter}
        title="Тендеры (договора с гос. закупа)"
        variant="paper"
        viewControl={false}
        emptyTitle={
          <span>
            Тут появится список тендеров (договоров) с сайта{' '}
            <a href="https://goszakup.gov.kz/" target="_blank" rel="noreferrer">
              гос. закупок
            </a>
          </span>
        }
      />
      <ModalTenderCreate
        companyId={!!company ? company.id : null}
        visible={!!govTender}
        closeModal={onCloseModal}
        data={
          !!govTender
            ? {
                name: govTender.anno.name_ru,
                price: govTender.contract_sum_wnds.toString(),
              }
            : undefined
        }
      />
    </>
  );
}

export default GovTendersSearch;
