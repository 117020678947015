import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Tender } from '@app/models';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loader } from '@app/components';
import { TenderInfo } from '@app/common';
import {
  activateTender,
  deleteTender,
  getTenderById,
  hideTender,
} from '@app/api';
import styled from 'styled-components';
import { IconBin, IconCloseEye, IconEye } from '@app/icons';
import { ModalConfirm } from '@app/modals';

interface Props {}

const StyledLoaderCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function PageProfileTender(props: Props) {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const [tender, setTender] = useState<Tender | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [tenderIsActive, setTenderIsActive] = useState<boolean>(
    tender?.active || false
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      const tenderResponse = await getTenderById(id as string);

      setTender(tenderResponse);
      setError(false);
      setPending(false);
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [id]);

  const onClickDelete = useCallback(async () => {
    try {
      if (tender?.id) {
        await deleteTender(tender.id);
        setIsDeleted((prevState) => !prevState);
      }
    } catch (e) {}
  }, [tender?.id]);
  const onClickDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const onClickActivateHideTender = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        e.preventDefault();
        e.stopPropagation();
        if (tender?.id) {
          if (tenderIsActive) {
            await hideTender(tender.id);
          } else {
            await activateTender(tender.id);
          }
          setTenderIsActive((prevState) => !prevState);
        }
      } catch (e) {}
    },
    [tender?.id, tenderIsActive]
  );

  const onEditTender = useCallback(() => {
    navigate(`/profile/tender/${id}/edit`);
  }, [id, navigate]);

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!tender) {
      return [];
    }

    const returnActionButtons = [];
    returnActionButtons.push(
      <Button
        text=""
        icon={IconBin}
        onClick={onClickDeleteModal}
        variant={isDeleted ? 'contained' : 'outlined'}
      />
    );
    returnActionButtons.push(
      <Button
        icon={tenderIsActive ? IconCloseEye : IconEye}
        color="primary"
        onClick={onClickActivateHideTender}
      />
    );

    returnActionButtons.push(
      <Button text="Редактировать" onClick={onEditTender} />
    );

    return [...returnActionButtons];
  }, [
    isDeleted,
    onClickActivateHideTender,
    onClickDeleteModal,
    onEditTender,
    tender,
    tenderIsActive,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (pending) {
    return (
      <StyledLoaderCenter>
        <Loader />
      </StyledLoaderCenter>
    );
  }

  if (!tender || error) {
    return null;
  }

  return (
    <>
      <TenderInfo
        tender={tender}
        viewReplies={true}
        actions={actionButtons}
        editable={false}
      />
      <ModalConfirm
        textSuccess={'Успешно удален!'}
        onAccept={onClickDelete}
        title={'Вы уверены, что хотите удалить?'}
        visible={deleteModalVisible}
        onClose={onCloseDeleteModal}
      />
    </>
  );
}

export default memo(PageProfileTender);
