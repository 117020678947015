import React, { memo, PropsWithChildren } from 'react';
import { Button, Paper, Switch } from '@app/components';
import './styles.scss';

interface Props extends PropsWithChildren {
  onChangeSwitch: (expanded: boolean) => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  expanded: boolean;
  title: string;
  description?: string;
  disabled?: boolean;
  loading?: boolean;
  disableSwitcher?: boolean;
  submitLabel?: string;
  disableSubmitLabel?: boolean;
  pending?: boolean;
  autoComplete?: 'on' | 'off';
  viewHead?: boolean;
  nullablePadding?: boolean;
}

function ExpandableForm(props: Props) {
  const {
    title,
    expanded,
    onChangeSwitch,
    onSubmit,
    description,
    disabled = false,
    loading = false,
    disableSwitcher = false,
    submitLabel = 'Создать',
    children,
    autoComplete = 'on',
    viewHead = true,
    nullablePadding = false,
    disableSubmitLabel = false,
  } = props;

  return (
    <form
      onSubmit={onSubmit}
      className="smr-expandable-form"
      autoComplete={autoComplete}
    >
      <Paper dense={nullablePadding}>
        {viewHead && (
          <div className="smr-expandable-form__header">
            {expanded && <p className="smr-expandable-form__title">{title}</p>}
            {!expanded && (
              <div>
                <p
                  className={`${
                    description?.length === 0
                      ? 'smr-expandable-form__description--not'
                      : 'smr-expandable-form__description'
                  }`}
                >
                  {title}
                </p>
                <p className="smr-expandable-form__title">{description}</p>
              </div>
            )}
            {!disableSwitcher && (
              <Switch
                className="smr-expandable-form__switch"
                checked={expanded}
                onChange={onChangeSwitch}
              />
            )}
          </div>
        )}
        {expanded && (
          <div
            className={
              nullablePadding
                ? 'smr-expandable-form__content_null_padding'
                : 'expandable-form__content'
            }
          >
            {children}
          </div>
        )}
      </Paper>
      {disableSubmitLabel || disabled || !onSubmit ? null : (
        <div className="smr-expandable-form__footer">
          <Button
            loading={loading}
            disabled={loading}
            text={submitLabel}
            type="submit"
            className="smr-expandable-form__submit"
          />
        </div>
      )}
    </form>
  );
}

export default memo(ExpandableForm);
