import React, { memo, PropsWithChildren } from 'react';

import { Footer, Header, MobileMenu } from '@app/components';
import './styles.scss';

interface LayoutDefaultProps {
  className?: string;
}

function LayoutDefault({
  children,
  className = '',
}: PropsWithChildren<LayoutDefaultProps>) {
  return (
    <div className="smr-default-layout">
      <Header />
      <div className={`smr-default-layout__content ${className}`.trim()}>
        {children}
      </div>
      <Footer />
      <MobileMenu />
    </div>
  );
}

export default memo(LayoutDefault);
