import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LayoutDefault } from '@app/layouts';
import { Button, Loader, StyledContainer } from '@app/components';
import { Reply, Tender, TenderCategory, UserType } from '@app/models';
import { useNavigate, useParams } from 'react-router-dom';
import { getTenderById } from '@app/api';
import { AuthorSection, TenderInfo, WelcomeBanner } from '@app/common';
import { ModalReport, ReplyModal } from '@app/modals';
import { useNotification, useUserContext } from '@app/providers';
import { IconCheckCircle16 } from '@app/icons';
import styled from 'styled-components';
import { isBefore } from 'date-fns';

interface Props {}

const Grid = styled(StyledContainer)`
  display: grid;
  grid-gap: 16px;
`;

const StyledLoader = styled.div`
  text-align: center;
  margin: 16px 0;
`;

function TenderPage(props: Props) {
  const { id } = useParams<'id'>();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const { user, showSignInModal, currentProfile } = useUserContext();
  const [tender, setTender] = useState<Tender | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [replyModalVisible, setReplyModalVisible] = useState<boolean>(false);
  const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      const tenderResponse = await getTenderById(id as string);

      setTender(tenderResponse);
      setError(false);
      setPending(false);
    } catch (e) {
      setError(true);
      setPending(false);
    }
  }, [id]);

  const onClickReply = useCallback(() => {
    if (!user.authenticated) {
      showSignInModal();

      return;
    }

    if (!isBefore(new Date(), new Date(tender!.endDate))) {
      showNotification({
        variant: 'error',
        message:
          'Срок действия объявления истек, к сожелению Вы не можете подать отклик',
      });

      return;
    }

    setReplyModalVisible(true);
  }, [showNotification, showSignInModal, tender, user.authenticated]);

  const onClickReport = useCallback(() => {
    if (!user.authenticated) {
      showSignInModal();

      return;
    }

    if (!isBefore(new Date(), new Date(tender!.endDate))) {
      showNotification({
        variant: 'error',
        message:
          'Срок действия объявления истек, к сожелению Вы не можете подать отклик',
      });

      return;
    }

    setReportModalVisible(true);
  }, [showNotification, showSignInModal, tender, user.authenticated]);

  const onClickShare = useCallback(async () => {
    try {
      if (!user.authenticated) {
        showSignInModal();

        return;
      }
      const url = `${window.location.hostname}/tender/${tender?.id}`;
      await navigator.clipboard.writeText(url);

      showNotification({
        variant: 'success',
        message: 'Ссылка скопирована в буфер обмена',
      });
    } catch (e) {
      showNotification({
        variant: 'error',
        message: 'Ссылка не была скопирована',
      });
    }
  }, [showNotification, showSignInModal, tender?.id, user.authenticated]);

  const onCloseReplyModal = useCallback(
    (reply?: Reply) => {
      setReplyModalVisible(false);

      if (!!reply) {
        setTender({
          ...tender!,
          replies: [...tender!.replies, reply],
        });
      }
    },
    [tender]
  );
  const onCloseReportModal = useCallback(() => {
    setReportModalVisible(false);
  }, []);

  const isOwner = useMemo<boolean>(() => {
    if (!user.authenticated || !tender) {
      return false;
    }

    return user.id === tender.ownerId;
  }, [user, tender]);

  const hasReply = useMemo<boolean>(() => {
    if (!user.authenticated || !tender) {
      return false;
    }

    return !!tender.replies.find((reply) => reply.ownerId === user.id);
  }, [tender, user]);

  const onEditTender = useCallback(() => {
    navigate(`/profile/tender/${id}/edit`);
  }, [id, navigate]);

  const actionButtons = useMemo<ReactNode[]>(() => {
    if (!tender) {
      return [];
    }

    const returnActionButtons = [
      <Button
        text="Поделиться"
        disabled={currentProfile.blocked}
        onClick={onClickShare}
      />,
    ];

    // <Button text="Пожаловаться" />
    if (
      !hasReply &&
      !isOwner &&
      isBefore(new Date(), new Date(tender.endDate))
    ) {
      returnActionButtons.push(
        <Button
          text="Пожаловаться"
          color="danger"
          disabled={
            currentProfile.blocked ||
            (!!user &&
              user.type === UserType.INDIVIDUAL &&
              !!tender &&
              [TenderCategory.SUPPLIER, TenderCategory.CONTRACTOR].indexOf(
                tender.category
              ) > -1)
          }
          onClick={onClickReport}
        />
      );

      returnActionButtons.push(
        <Button
          text="Откликнуться"
          disabled={
            !!user &&
            user.type === UserType.INDIVIDUAL &&
            !!tender &&
            [TenderCategory.SUPPLIER, TenderCategory.CONTRACTOR].indexOf(
              tender.category
            ) > -1
          }
          icon={IconCheckCircle16}
          onClick={onClickReply}
        />
      );
    }

    if (currentProfile.id === tender.ownerId) {
      returnActionButtons.push(
        <Button
          text="Редактировать"
          onClick={onEditTender}
          disabled={currentProfile.blocked}
        />
      );
    }

    return [
      ...returnActionButtons,
      // <Button icon={IconShare16} />,
      // <Button icon={IconBookmark16} />,
    ];
  }, [
    currentProfile.blocked,
    currentProfile.id,
    hasReply,
    isOwner,
    onClickReply,
    onClickReport,
    onClickShare,
    onEditTender,
    tender,
    user,
  ]);

  const findCompany = tender?.owner?.companies?.find(
    (val) => val.id === tender?.companyId
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <LayoutDefault>
      <WelcomeBanner />
      {pending && (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      )}
      {!!tender && (
        <Grid>
          <TenderInfo tender={tender} actions={actionButtons} />
          <AuthorSection author={tender.owner} company={findCompany} />
        </Grid>
      )}
      {user.authenticated && (
        <>
          <ReplyModal
            tender={tender}
            visible={replyModalVisible}
            onClose={onCloseReplyModal}
          />
          {tender && (
            <ModalReport
              tender={tender}
              visible={reportModalVisible}
              onClose={onCloseReportModal}
            />
          )}
        </>
      )}
    </LayoutDefault>
  );
}

export default memo(TenderPage);
